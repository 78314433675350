import { FC } from "react";

import { Outlet } from "react-router-dom";

import Header from "@components/Header/Header";
import Sidebar from "@components/Sidebar/Sidebar";

const AdminLayout: FC = () => {
  return (
    <div className="app">
      <div className="container-fluid p-0">
        <Header />
        <div className="d-flex flex-nowrap">
          <aside className="bg-primary col p-0 m-0" style={{ maxWidth: "255px", borderRight: "1px solid black" }}>
            <Sidebar />
          </aside>
          <main className="col w-auto" style={{ padding: 0, margin: 0 }}>
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
