import { ButtonHTMLAttributes, FC, ReactNode } from "react";

import cn from "classnames";

import useTheme from "@hooks/useThemes";

import styles from "./Button.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

const Button: FC<ButtonProps> = ({ className, children, ...props }) => {
  const { theme } = useTheme();

  return (
    <button
      type="submit"
      className={cn(
        styles.root,
        {
          [styles.dark]: theme === "dark",
          [styles.light]: theme === "light",
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
