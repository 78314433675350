import axios from "axios";

import { ADMIN_SERVER_URL, FILE_SERVER_URL, SERVER_URL } from "./constants";
import {setupInterceptors} from "./interceptor";

export const apiDefault = axios.create({
    baseURL: `${SERVER_URL}/v1`,
    withCredentials: true,
});

export const api = setupInterceptors(
    axios.create({
      baseURL: `${SERVER_URL}/v1`,
      withCredentials: true,
    })
);

export const apiAdmin = setupInterceptors(
  axios.create({
    baseURL: `${ADMIN_SERVER_URL}/v1/admin`,
    withCredentials: true
  })
)

export const fileApi = setupInterceptors(
  axios.create({
    baseURL: `${FILE_SERVER_URL}/v1`,
    withCredentials: true
  })
)

export default api;
