import { ReactNode, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import AuthContext from "@context/AuthContext";
import { api, apiDefault } from "@core/axios";
import { SIGN_IN_PAGE_LINK } from "@core/constants";
import { setAuthHeader } from "@core/interceptor";
import useStorage from "@hooks/useStorage";
import useToast from "@hooks/useToast";
import { SignInCredentials, userInitialState } from "@interfaces/IUser";
import { userService } from "@services/userService";
import { createSession, getAccessToken, removeSession } from "@utils/tokens";

type Props = {
  children: ReactNode;
};

const AuthProvider = (props: Props) => {
  const { children } = props;
  const { addToast } = useToast();

  const [user, setUser] = useStorage("user", userInitialState);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const token = getAccessToken();
  const isAuth = Boolean(token);

  const signIn = async (signInData: SignInCredentials) => {
    const { email, password } = signInData;

    try {
      const response = await apiDefault.post("/auth/sign-in", {
        email,
        password
      });
      const { AccessToken } = response.data;
      createSession({ token: AccessToken });

      const userResponse = await userService.getProfile();
      if (userResponse) {
        setUser(userResponse);
      }
      setAuthHeader({ request: api.defaults, token });
    } catch (e: any) {
      if (e.response.status >= 400) {
        return e.response.data.message;
      }
    }
  };

  const signOut = () => {
    setUser(userInitialState);
    removeSession();
    setLoadingUserData(false);
    navigate(SIGN_IN_PAGE_LINK);
  };

  useEffect(() => {
    if (!token) {
      setUser(userInitialState);
      removeSession();
      setLoadingUserData(false);
    }
  }, [navigate, pathname, token, setUser]);

  useEffect(() => {
    const token = getAccessToken();

    const getUserData = async () => {
      setLoadingUserData(true);

      try {
        const response = await userService.getProfile();
        if (response) {
          setUser(response);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      } finally {
        setLoadingUserData(false);
      }
    };

    if (token) {
      setAuthHeader({ request: api.defaults, token });
    }

    if (!user) {
      getUserData();
    }

    setLoadingUserData(false);
  }, [addToast, user, setUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        loadingUserData,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
