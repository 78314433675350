import { v4 as uuidv4 } from "uuid";

export const generateRequestID = () => {
  return uuidv4();
};

export const bytesToMB = (bytes: number) => {
  if (isNaN(bytes) || bytes < 0) {
    throw new Error("Invalid input: bytes must be a non-negative number");
  }

  const MB = bytes / (1024 * 1024);
  return MB.toFixed(2); // Округляем до двух знаков после запятой
};

export const compareRows = (originalRow: any, updatedRow: any) => {
  interface IChanges {
    [key: string]: any;
  }

  const changes: IChanges = {};

  Object.keys(originalRow).forEach((key) => {
    if ((originalRow as Record<string, any>)[key] !== (updatedRow as Record<string, any>)[key]) {
      changes[key] = (updatedRow as Record<string, any>)[key];
    }
  });

  return changes;
};
