import { CSSProperties, FC, ReactNode } from "react";

import cn from "classnames";

import useTheme from "@hooks/useThemes";

interface CardHeaderProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const CardHeader: FC<CardHeaderProps> = ({ children, className, style }) => {
  const { theme } = useTheme();

  const cardDefaultStyle = {
    backgroundColor: theme === "light" ? "#f8f8f8" : "#353649FF"
  };

  return (
    <div
      className={cn("card-header", className)}
      style={{ ...cardDefaultStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default CardHeader;
