import { createContext } from 'react';

import { AddToast, IToast } from '@providers/ToastProvider';

export type ToastContextType = {
  toasts: IToast[];
  addToast: AddToast;
};

const ToastContext = createContext({} as ToastContextType);

export default ToastContext;
