import { Dispatch, FC, Fragment, SetStateAction, useEffect, useId, useState } from "react";

import { Link } from "react-router-dom";

import PencilSquare from "@components/Icons/PencilSquare";
import TrashIcon from "@components/Icons/TrashIcon";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import { getUserInfoPageLinkByID, STATUS_CREATED } from "@core/constants";
import useToast from "@hooks/useToast";
import { IAdminUserData } from "@interfaces/IAdmin";
import { ICompanyResponse } from "@interfaces/ICompany";
import { IRole } from "@interfaces/IRole";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import { formatDateTime } from "@utils/date";
import { compareRows } from "@utils/utils";
import { isValidEmail } from "@utils/validators";

interface TableDataProps {
  tableData: IAdminUserData[];
  setTableData: Dispatch<SetStateAction<IAdminUserData[]>>;
  oldTableData: IAdminUserData[];
  setOldTableData: Dispatch<SetStateAction<IAdminUserData[]>>;
  companies: ICompanyResponse[];
  roles: IRole[];
  itemViewCount: number;
  setItemViewCount: Dispatch<SetStateAction<number>>;
  currentPage: number;
}

const UserTableHead: FC = () => {
  return (
    <tr>
      <th className="text-center">№</th>
      <AdminAccessWrapper>
        <th className="text-center">Действие</th>
      </AdminAccessWrapper>
      <th className="text-center">Компания</th>
      <th className="text-center">Email сотрудника</th>
      <th className="text-center">Роль пользователя</th>
      <th className="text-center">Статус аккаунта</th>
      <th className="text-center">Дата последнего посещения</th>
      <th className="text-center">Дата создания аккаунта</th>
    </tr>
  );
};

const EmptyRow = () => {
  return (
    <tr>
      <td className="align-middle text-center"></td>
      <AdminAccessWrapper>
        <td className="align-middle text-center"></td>
      </AdminAccessWrapper>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
      <td className="align-middle text-center"></td>
    </tr>
  );
};

const TableData: FC<TableDataProps> = ({
                                         tableData,
                                         setTableData,
                                         oldTableData,
                                         setOldTableData,
                                         companies,
                                         roles,
                                         itemViewCount,
                                         setItemViewCount,
                                         currentPage
                                       }) => {
  const id = useId();
  const { addToast } = useToast();

  const [searchValue, setSearchValue] = useState("");

  const filteredTableData = tableData.filter((val: IAdminUserData) => {
    return val.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
  });

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
  const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

  const handleSaveRowChanges = async () => {
    setIsEditMode(false);

    const row = tableData.find(row => row.ID === rowIDToEdit);
    const oldRow = oldTableData.find(row => row.ID === rowIDToEdit);
    const changedFields = compareRows(oldRow, row);
    const formData = {};

    if (changedFields.companyName) {
      const companyID = companies.find(row => row.name === changedFields.companyName)?.ID;
      if (companyID === 0) {
        Object.assign(formData, { isCompanyID: true });
      }
      Object.assign(formData, { companyID: companyID });
    }

    if (changedFields.roleName) {
      const roleID = roles.find(row => row.name === changedFields.roleName)?.ID;
      Object.assign(formData, { roleID: roleID });
    }

    if (changedFields.email) {
      if (isValidEmail(changedFields.email)) {
        Object.assign(formData, { email: changedFields.email });
      } else {
        setTableData(oldTableData);
        return;
      }
    }

    if (changedFields.hasOwnProperty("isBanned")) {
      Object.assign(formData, { isBanned: changedFields.isBanned });
    }

    if (!(Object.entries(formData).length === 0) && rowIDToEdit) {
      try {
        const response = await adminService.updateUserTableRow(rowIDToEdit, formData);
        if (response.status === STATUS_CREATED) {
          setOldTableData(tableData);
          addToast(response.data.message, "success");
        }
      } catch (e: any) {
        setTableData(oldTableData);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };

  const handleEditButtonChange = (rowID: number) => {
    setIsEditMode(true);
    setRowIDToEdit(rowID);
  };

  const handleCloseEditButtonChange = () => {
    setIsEditMode(false);
    setTableData(oldTableData);
  };

  const handleRemoveRowChange = async (rowID: number) => {
    const confirm = window.confirm(`Вы уверены, что хотите удалить пользователя с id = ${rowID}`);
    if (confirm) {
      const confirm2 = window.confirm(`Вы точно уверены, что хотите удалить пользователя с id = ${rowID}`);
      if (confirm2) {
        try {
          const response = await adminService.deleteUserAccount(rowID);
          if (response.status === STATUS_CREATED) {
            setTableData(prevState => prevState.filter(row => row.ID !== rowID));
            setOldTableData(prevState => prevState.filter(row => row.ID !== rowID));
            addToast(response.data.message, "success");
          }
        } catch (e: any) {
          setTableData(oldTableData);
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }

      }
    }
  };

  const handleOnChangeField = (e: any, rowID: number) => {
    let value: string;

    const { name: fieldName } = e.target;

    if (fieldName === "companyName" || fieldName === "roleName") {
      value = e.target.options[e.target.selectedIndex].text;
    } else if (fieldName === "isBanned") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setTableData(prevData =>
      prevData.map(row =>
        row.ID === rowID
          ? { ...row, [fieldName]: value }
          : row
      )
    );
  };

  useEffect(() => {
    if (rowIDToEdit !== isFirstEditRowID && rowIDToEdit) {
      setIsFirstEditRowID(rowIDToEdit);
      setTableData(oldTableData);
    }
  }, [rowIDToEdit, isFirstEditRowID, oldTableData, setTableData]);

  return (
    <Fragment>
      <Row className="mt-3">
        <TableViewItem
          itemViewCount={itemViewCount}
          setItemViewCount={setItemViewCount}
        />
        <TableSearchItem
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Row>
      <div className="table-responsive">
        <table className="table table-striped table-bordered my-0">
          <thead>
          <UserTableHead />
          </thead>
          <tbody>
          {filteredTableData.length > 0 ? filteredTableData.map((row, key: number) => (
            <tr key={row.ID}>
              <td className={"align-middle text-center"}>{getRowNumber(key)}</td>

              <AdminAccessWrapper>
                <td className={"align-middle text-center"}>
                  {isEditMode && rowIDToEdit === row.ID ? (
                    <>
                      <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                        <i className="fs-5 bi bi-save me-2" />
                      </button>
                      <button style={{ all: "unset" }} onClick={() => handleCloseEditButtonChange()}>
                        <PencilSquare className="fs-5 text-danger" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.ID)}>
                        <i className="fs-5 bi bi-pencil-square me-2" />
                      </button>
                      <button style={{ all: "unset" }} onClick={() => handleRemoveRowChange(row.ID)}>
                        <TrashIcon className="fs-5 me-2" />
                      </button>
                    </>
                  )}
                </td>
              </AdminAccessWrapper>

              {isEditMode && rowIDToEdit === row.ID ? (
                <td className={"align-middle"}>
                  <select
                    className="d-inline-block form-select form-select-sm"
                    name="companyName"
                    onChange={(e) => handleOnChangeField(e, row.ID)}
                    defaultValue={companies.find(r => r.name === row.companyName)?.ID}
                  >
                    {companies.map((item) => (
                      <option key={item.ID} value={item.ID}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
              ) : (
                <td className="align-middle text-center">{row.companyName}</td>
              )}

              {isEditMode && rowIDToEdit === row.ID ? (
                <td className={"align-middle"}>
                  <input
                    type="text"
                    id={`email-${id}`}
                    className="form-control form-control-sm"
                    placeholder="email"
                    name="email"
                    onChange={(e) => handleOnChangeField(e, row.ID)}
                    value={row.email}
                  />
                </td>
              ) : (
                <td className="align-middle text-center">
                  <Link to={getUserInfoPageLinkByID(row.ID)}>
                    {row.email}
                  </Link>
                </td>
              )}

              {isEditMode && rowIDToEdit === row.ID ? (
                <td className={"align-middle"}>
                  <select
                    className="d-inline-block form-select form-select-sm"
                    name="roleName"
                    onChange={(e) => handleOnChangeField(e, row.ID)}
                    defaultValue={roles.find(r => r.name === row.roleName)?.ID}
                  >
                    {roles.map((item) => (
                      <option key={item.ID} value={item.ID}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </td>
              ) : (
                <td className="align-middle text-center">{row.roleName}</td>
              )}

              {isEditMode && rowIDToEdit === row.ID ? (
                <td className={"align-middle"}>
                  <div className="col-12 d-flex">
                    <label htmlFor={`is-upload-error-${id}`} className="me-2">
                      Заблокирован
                    </label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        name="isBanned"
                        id={`is-upload-error-${id}`}
                        onChange={(e) => handleOnChangeField(e, row.ID)}
                        checked={row.isBanned}
                      />
                    </div>
                  </div>
                </td>
              ) : (
                <td className={"align-middle"}>
                  <div
                    className={`alert ${
                      row.isBanned ? "alert-danger" : "alert-success"
                    }  text-center p-0 m-0`}>
                    {row.isBanned ? "Заблокирован" : "Активен"}
                  </div>
                </td>
              )}

              <td className="align-middle text-center">
                {row.lastVisitedAt ? formatDateTime(row.lastVisitedAt) : "Нет посещений"}
              </td>
              <td className="align-middle text-center">
                {formatDateTime(row.createdAt)}
              </td>

            </tr>
          )) : (
            <EmptyRow />
          )}
          </tbody>
          <tfoot>
          <UserTableHead />
          </tfoot>
        </table>
      </div>
    </Fragment>
  );
};

export default TableData;