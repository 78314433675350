import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Card from '@components/Card/Card';
import CardBody from '@components/Card/CardBody';
import CardHeader from '@components/Card/CardHeader';
import { IContract } from '@interfaces/IContract';
import { getDate } from '@utils/date';

interface ContractInfoProps {
  selectedContract: IContract;
}

const ContractInfo: FC<ContractInfoProps> = ({ selectedContract }) => {
  const { t } = useTranslation();

  return (
    <Card className="mt-2" style={{ maxWidth: '800px' }}>
      <CardHeader>
        {t('contract-information-text')}
      </CardHeader>
      <CardBody>
        <div className="me-2">
          {t('contract-date-text')}:{' '}
          {getDate(selectedContract.dateOfConclusion)}
        </div>
        <div className="me-2">
          {t('licenses-left-text')}: {selectedContract.licAvailable}
        </div>
        <div className="me-2">
          {t('total-licenses-text')}: {selectedContract.licCount}
        </div>
      </CardBody>

    </Card>
  );
};

export default ContractInfo;
