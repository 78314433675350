import { FC } from 'react';

import Card from '@components/Card/Card';
import CardBody from '@components/Card/CardBody';
import CardHeader from '@components/Card/CardHeader';
import Col from '@components/Wrapper/Col/Col';
import Row from '@components/Wrapper/Row';
import { ICompany } from '@interfaces/ICompany';

interface CompanyInfoProps {
  company: ICompany;
}

const CompanyInfo: FC<CompanyInfoProps> = ({ company }) => {
  return (
    <Card className="mb-3">
      <CardHeader className="py-3">
        <p className="m-0 fw-bold">Данные компании</p>
      </CardHeader>
      <CardBody>
        <Row>
          <Col className="mb-3">
            <label className="form-label" htmlFor="compName">
              <strong>Наименование компании</strong>
            </label>
            <input
              className="form-control"
              type="text"
              id="compName"
              placeholder="Количество зарегистрированных пользователей"
              name="compName"
              value={company.name}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <label className="form-label" htmlFor="users_count">
              <strong>
                Количество зарегистрированных аккаунтов компании
              </strong>
            </label>
            <input
              className="form-control"
              type="text"
              id="users_count"
              placeholder="Количество зарегистрированных пользователей"
              name="users_count"
              value={company.users_count}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <label className="form-label" htmlFor="contract_count">
              <strong>Количество контрактов</strong>
            </label>
            <input
              className="form-control"
              type="text"
              id="contract_count"
              placeholder="Компания"
              name="contract_count"
              value={company.contract_count}
              disabled
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompanyInfo;
