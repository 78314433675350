import { FC, ReactNode, useContext } from "react";

import AuthContext from "@context/AuthContext";
import { ACCESS_LEVEL_ADMIN } from "@core/constants";

interface AdminAccessProps {
  children: ReactNode;
}

const AdminAccessWrapper: FC<AdminAccessProps> = ({ children }) => {
  const userRole = useContext(AuthContext).user.accessLevel;

  return (
    <>
      {userRole && userRole >= ACCESS_LEVEL_ADMIN && children}
    </>
  );
};

export default AdminAccessWrapper;