import { FC } from 'react';

import cn from 'classnames';

interface TrashIconProps {
  className?: string;
}

const TrashIcon: FC<TrashIconProps> = ({ className }) => {
  return (
    <i className={cn('bi bi-trash', className)} />
  );
};

export default TrashIcon;