import { FC, ReactNode } from 'react';

import cn from 'classnames';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}
const Container: FC<ContainerProps> = ({children, className}) => {
  return (
    <div className={cn("container", className)}>{children}</div>
  );
};

export default Container;
