import { FC, ReactNode } from "react";

import cn from "classnames";

import styles from "@components/FallingStars/FallingStars.module.scss";

interface FallingStarsContainerProps {
  children: ReactNode;
}

const FallingStarsContainer: FC<FallingStarsContainerProps> = ({ children }) => {
  return (
    <div
      className={cn("position-fixed top-0 start-0 w-100 h-100", styles.falling_stars)}
      style={{ zIndex: 1000 }}
    >
      {children}
    </div>
  );
};

export default FallingStarsContainer;