import { FC, ReactNode } from 'react';

import cn from 'classnames';

interface NavbarProps {
  children: ReactNode;
  className?: string;
}

const Navbar: FC<NavbarProps> = ({ children, className }) => {
  return (
    <ul className={cn('navbar-nav me-auto mb-2 mb-lg-0', className)}>{children}</ul>
  );
};

export default Navbar;
