import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

interface HwFixComponentsProps {
  hwFix: boolean;
  setHwFix: Dispatch<SetStateAction<boolean>>;
}

const HwFixComponents: FC<HwFixComponentsProps> = ({ hwFix, setHwFix }) => {
  const id = useId();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHwFix(e.target.checked);
  };

  return (
    <div className="col-12 d-flex">
      <label htmlFor={`hw-fix-${id}`} className="me-2">
        Жесткая привязка оборудования:
      </label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`hw-fix-${id}`}
          name="hw-fix"
          checked={hwFix}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default HwFixComponents;