export interface IDashboard {
  licenseStatistics: {
    licensesIssuedPerYear: number;
    licensesIssuedPerMonth: number;
    licensesIssuedPerDay: number;
    percentContractIssued: number;
  },
  downloadSoftwareCount: number;
  totalLicensesIssued: number;
  totalUsersRegistered: number;
  totalCompanyRegistered: number;
  totalContractsRegistered: number;
}

export const dashboardInitialState: IDashboard = {
  licenseStatistics: {
    licensesIssuedPerYear: 0,
    licensesIssuedPerMonth: 0,
    licensesIssuedPerDay: 0,
    percentContractIssued: 0
  },
  downloadSoftwareCount: 0,
  totalLicensesIssued: 0,
  totalUsersRegistered: 0,
  totalCompanyRegistered: 0,
  totalContractsRegistered: 0
};

export interface IAvgStat {
  load1: number;
  load5: number;
  load15: number;
}

export interface IOsStats {
  memTotal: number;
  memAvailable: number;
  memUsed: number;
  hddMemTotal: number;
  hddMemAvailable: number;
  hddMemUsed: number;
  uptime: string;
  avgStat: IAvgStat;
}

const avgStatInitialState: IAvgStat = {
  load1: 0,
  load5: 0,
  load15: 0
};

export const osStatsInitialState: IOsStats = {
  memTotal: 0,
  memAvailable: 0,
  memUsed: 0,
  hddMemTotal: 0,
  hddMemAvailable: 0,
  hddMemUsed: 0,
  uptime: "",
  avgStat: avgStatInitialState
};