import { FC, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PencilSquare from "@components/Icons/PencilSquare";
import TrashIcon from "@components/Icons/TrashIcon";
import PageNavigate from "@components/Navigate/PageNavigate";
import PaginationView from "@components/Pagination/PaginationView";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import { COMPANIES_CREATE_PAGE_LINK, COMPANIES_PAGE_LINK, getCompanyPageByID, STATUS_CREATED } from "@core/constants";
import useToast from "@hooks/useToast";
import { companyTableInitialState, ICompanyTableData } from "@interfaces/ICompany";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import { formatDateTime } from "@utils/date";
import { compareRows } from "@utils/utils";

const AdminPanelCompanyPage: FC = () => {
  const { addToast } = useToast();

  const [itemViewCount, setItemViewCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState<ICompanyTableData[]>([companyTableInitialState]);
  const [oldTableData, setOldTableData] = useState<ICompanyTableData[]>([companyTableInitialState]);

  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [isCompanyExist, setIsCompanyExist] = useState(false);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
  const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  const filteredTableData = tableData.filter((val) => {
    return val.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getCompaniesContract(
          itemViewCount,
          currentPage
        );
        if (response && response.data !== null) {
          setTableData(response.data);
          setItemCount(response.count);
          setOldTableData(response.data);
          setIsCompanyExist(true);
          setLastPage(response.lastPage);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [itemViewCount, currentPage, addToast]);

  useEffect(() => {
    if (rowIDToEdit && rowIDToEdit !== isFirstEditRowID) {
      setIsFirstEditRowID(rowIDToEdit);
      setTableData(oldTableData);

    }
  }, [rowIDToEdit, isFirstEditRowID, oldTableData]);

  const handleEditButtonChange = (rowID: number) => {
    setIsEditMode(true);
    setRowIDToEdit(rowID);
  };

  const handleCloseEditButtonChange = () => {
    setIsEditMode(false);
    setTableData(oldTableData);
  };

  const handleRemoveRowChange = async (rowID: number) => {
    const confirm = window.confirm(`Вы уверены, что хотите удалить компанию с id = ${rowID}`);
    if (confirm) {
      const confirm2 = window.confirm(`Вы точно уверены, что хотите удалить компанию с id = ${rowID}`);
      if (confirm2) {
        try {
          const response = await adminService.deleteCompany(rowID);
          if (response.status === STATUS_CREATED) {
            setTableData(prevState => prevState.filter(row => row.ID !== rowID));
            setOldTableData(prevState => prevState.filter(row => row.ID !== rowID));
            addToast(response.data.message, "success");
          }
        } catch (e: any) {
          setTableData(oldTableData);
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }

      }
    }
  };

  const handleOnChangeField = (e: any, rowID: number) => {
    let value: string;

    const { name: fieldName } = e.target;

    if (fieldName === "companyName" || fieldName === "roleName") {
      value = e.target.options[e.target.selectedIndex].text;
    } else if (fieldName === "isBanned") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setTableData(prevData =>
      prevData.map(row =>
        row.ID === rowID
          ? { ...row, [fieldName]: value }
          : row
      )
    );
  };

  const handleSaveRowChanges = async () => {
    setIsEditMode(false);
    const row = tableData.find(row => row.ID === rowIDToEdit);
    const oldRow = oldTableData.find(row => row.ID === rowIDToEdit);
    const changedFields = compareRows(oldRow, row);
    const formData = {};

    if (changedFields.name) {
      Object.assign(formData, { name: changedFields.name });
    }

    if (!(Object.entries(formData).length === 0) && rowIDToEdit) {
      try {
        const response = await adminService.updateCompanyTableRow(rowIDToEdit, formData);
        if (response.status === STATUS_CREATED) {
          setOldTableData(tableData);
          addToast(response.data.message, "success");
        }
      } catch (e: any) {
        setTableData(oldTableData);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };

  const CompanyTableHead: FC = () => {
    return (
      <tr className="text-center">
        <th>№</th>
        <AdminAccessWrapper>
          <th className="text-center">Действие</th>
        </AdminAccessWrapper>
        <th className="text-center">Компания</th>
        <th className="text-center">Количество контрактов</th>
        <th className="text-center">Дата регистрации компании</th>
      </tr>
    );
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Компании</h6>
      </CardHeader>
      <PageNavigate
        current={COMPANIES_PAGE_LINK}
        to={COMPANIES_CREATE_PAGE_LINK}
        title={"Добавить"}
      />
      {isCompanyExist ? (
        <CardBody>
          <Row>
            <TableViewItem
              itemViewCount={itemViewCount}
              setItemViewCount={setItemViewCount}
            />
            <TableSearchItem
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Row>
          <div
            className="table-responsive table mt-2"
            id="dataTable"
            role="grid"
            aria-describedby="dataTable_info">
            <table className="table table-striped table-bordered my-0" id="dataTable"
            >
              <thead>
              <CompanyTableHead />
              </thead>
              <tbody>
              {filteredTableData.map((row, key: number) => (
                <tr key={row.ID}>
                  <td className="align-middle text-center">{getRowNumber(key)}</td>

                  <AdminAccessWrapper>
                    <td className="align-middle text-center">
                      {isEditMode && rowIDToEdit === row.ID ? (
                        <>
                          <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                            <i className="fs-5 bi bi-save me-2" />
                          </button>
                          <button style={{ all: "unset" }} onClick={() => handleCloseEditButtonChange()}>
                            <PencilSquare className="fs-5 text-danger" />
                          </button>
                        </>
                      ) : (
                        <>
                          <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.ID)}>
                            <i className="fs-5 bi bi-pencil-square me-2" />
                          </button>
                          {!(row.contractCount > 0) &&
                            <button style={{ all: "unset" }} onClick={() => handleRemoveRowChange(row.ID)}>
                              <TrashIcon className="fs-5 me-2" />
                            </button>
                          }
                        </>
                      )}
                    </td>
                  </AdminAccessWrapper>

                  {isEditMode && rowIDToEdit === row.ID ? (
                    <td className={"align-middle"}>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="name"
                        name="name"
                        onChange={(e) => handleOnChangeField(e, row.ID)}
                        value={row.name}
                      />
                    </td>
                  ) : (
                    <td className="align-middle text-center">{row.name}</td>
                  )}

                  <td className="align-middle text-center">
                    {row.contractCount > 0 ? (
                      <Link to={getCompanyPageByID(row.ID)}>
                        {row.contractCount}
                      </Link>
                    ) : (
                      row.contractCount
                    )}
                  </td>
                  <td className="align-middle text-center">{formatDateTime(row.createdAt)}</td>
                </tr>
              ))}
              </tbody>
              <tfoot>
              <CompanyTableHead />
              </tfoot>
            </table>
          </div>
          <Row>
            <PaginationView
              currentPage={currentPage}
              lastPage={lastPage}
              itemViewCount={itemViewCount}
              itemCount={itemCount}
              setCurrentPage={setCurrentPage}
            />
          </Row>
        </CardBody>
      ) : (
        <CardBody>
          <div>Компании не зарегистрированы в системе!</div>
          <Link to={COMPANIES_CREATE_PAGE_LINK}>Добавьте компанию</Link>
        </CardBody>
      )}
    </Card>
  );
};

export default AdminPanelCompanyPage;
