import { FC, ReactNode, useEffect, useState } from "react";

import FallingStars from "@components/FallingStars/FallingStars";
import FallingStarsContainer from "@components/FallingStars/FallingStarsContainer";
import FallingStarsContext from "@context/FallingStarsContext";
import { isHolidaySeason } from "@utils/holidays";

interface FallingProviderProps {
  children: ReactNode;
}

interface Star {
  id: number;
  left: number;
  size: number;
  duration: number;
}

const FallingStarsProvider: FC<FallingProviderProps> = ({ children }) => {
  const MAX_STARS = 50;

  const [stars, setStars] = useState<Star[]>([]);

  const [isFallingStars, setIsFallingStars] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("falling_stars");
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    localStorage.setItem("falling_stars", JSON.stringify(isFallingStars));
  }, [isFallingStars, setIsFallingStars]);

  if (localStorage.getItem("falling_stars") === null) {
    localStorage.setItem("falling_stars", JSON.stringify(true));
    setIsFallingStars(true);
  }

  useEffect(() => {
    const createStar = () => {
      setStars((prevStars) => {
        if (prevStars.length >= MAX_STARS) {
          return prevStars;
        }

        const newStar: Star = {
          id: Date.now() + Math.random(),
          left: Math.random() * 100,
          size: Math.random() * 4 + 2,
          duration: Math.random() * 2 + 3 + 4
        };

        setTimeout(() => {
          setStars((prevStars) =>
            prevStars.filter((star) => star.id !== newStar.id)
          );
        }, newStar.duration * 1000);

        return [...prevStars, newStar];
      });
    };

    if (isHolidaySeason() && isFallingStars) {
      const interval = setInterval(createStar, 250);
      return () => clearInterval(interval);
    } else {
      setStars([]);
    }
  }, [isFallingStars]);

  return (
    <FallingStarsContext.Provider
      value={{
        isFallingStars,
        setIsFallingStars
      }}
    >
      {children}
      {isHolidaySeason() && (
        <FallingStarsContainer>
          {stars.map((star) => (
            <FallingStars
              key={star.id}
              left={star.left}
              height={star.size}
              width={star.size}
              animationDuration={star.duration}
            />
          ))}
        </FallingStarsContainer>
      )}
    </FallingStarsContext.Provider>
  );

};

export default FallingStarsProvider;