import { CSSProperties, FC, ReactNode } from "react";

import cn from "classnames";

import useTheme from "@hooks/useThemes";

interface CardBodyProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const CardBody: FC<CardBodyProps> = ({ children, className, style }) => {
  const { theme } = useTheme();

  const cardDefaultStyle = {
    backgroundColor: theme === "light" ? "#FFFFFF" : "#212529"//'#353649FF'
  };

  return (
    <div
      className={cn("card-body", className)}
      style={{ ...cardDefaultStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default CardBody;
