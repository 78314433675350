export interface IContract {
  contractID: number;
  contractNumber: string;
  isOrderNumberExist: boolean;
  dateOfConclusion: string;
  licCount: number;
  licAvailable: number;
  companyID: number;
  createdAt: string;
  updatedAt: string;
}

export const contractInitialState = {
  contractID: 0,
  contractNumber: "",
  isOrderNumberExist: false,
  dateOfConclusion: "",
  licCount: 0,
  licAvailable: 0,
  companyID: 0,
  createdAt: "",
  updatedAt: ""
};

export interface IContractsWithCompany {
  companyID: number;
  companyName: string;
  contractID: number;
  contractNumber: string;
  ordersCount: number;
  dateOfConclusion: string;
  isOrderNumberExist: boolean;
  licAvailable: number;
  licCount: number;
  updatedAt: string;
  createdAt: string;
}

export interface IContractsWithCompanyDataResponse {
  data: IContractsWithCompany[] | null;
  count: number;
  lastPage: number;
  currentPage: number;
}

export const contractWithCompanyInitialState: IContractsWithCompany = {
  companyID: 0,
  companyName: "",
  contractID: 0,
  contractNumber: "",
  ordersCount: 0,
  dateOfConclusion: "",
  isOrderNumberExist: false,
  licAvailable: 0,
  licCount: 0,
  updatedAt: "",
  createdAt: ""
};

export interface IPluginsInput {
  smtcp: boolean;
  opc_ua: boolean;
  modbus: boolean;
  redundancy: boolean;
  elna: boolean;
  online_transfer: boolean;
  archiver: boolean;
}

export interface IContractInput {
  company_id: number,
  contract_number: string,
  is_order_number_exist: boolean,
  date_of_conclusion: string;
  lic_count: number;
  lic_version: number;
  hw_fix: boolean;
  never_expire: boolean;
  description: string;
  expiration_date?: string;
  plugins: IPluginsInput;
  warranty_obligations?: {
    is_debt_paid?: boolean;
    debt_paid_date?: string;
    details: string;
    doc_id: string;
  }
}

export interface IContractOrderInput {
  company_id: number,
  contract_id: number,
  order_number: string,
  is_order_number_exist: boolean,
  date_of_conclusion: string;
  lic_count: number;
  lic_version: number;
  hw_fix: boolean;
  never_expire: boolean;
  description: string;
  expiration_date?: string;
  plugins: IPluginsInput
}

export interface IOrder {
  OrderID: number;
  OrderNumber: string;
  OrderLicCount: number;
  OrderLicAvailable: number;
  OrderDateOfConclusion: string;
}

export interface IContractsWithOrders {
  ContractID: number;
  ContractNumber: string;
  ContractLicCount: number;
  ContractLicAvailable: number;
  ContractDateOfConclusion: string;
  Orders: IOrder[];
}

export const contractsWithOrdersInitialState: IContractsWithOrders = {
  ContractID: 0,
  ContractNumber: "",
  ContractLicCount: 0,
  ContractLicAvailable: 0,
  ContractDateOfConclusion: "",
  Orders: [{
    OrderID: 0,
    OrderNumber: "",
    OrderLicCount: 0,
    OrderLicAvailable: 0,
    OrderDateOfConclusion: ""
  }]
};

export interface IContractWithUserAndCompanyByCompanyID {
  ContractID: number;
  ContractNumber: string;
  CreatedAt: string;
  DateOfConclusion: string;
  IsOrderNumberExist: boolean;
  LicAvailable: number;
  LicCount: number;
  UpdatedAt: string;
  UserID: number;
  UserName: string;
  UserSurname: string;
  CompanyName: string;
  WarrantyExist: boolean;
}

export interface IContractWithUserAndCompanyByCompanyIDResponse {
  data: IContractWithUserAndCompanyByCompanyID[];
  count: number;
  currentPage: number;
  lastPage: number;
}

export const contractWithUserAndCompanyByCompanyIDInitialState: IContractWithUserAndCompanyByCompanyID = {
  ContractID: 0,
  ContractNumber: "",
  CreatedAt: String(new Date()),
  DateOfConclusion: String(new Date()),
  IsOrderNumberExist: false,
  LicAvailable: 0,
  LicCount: 0,
  UpdatedAt: String(new Date()),
  UserID: 0,
  UserName: "",
  UserSurname: "",
  CompanyName: "",
  WarrantyExist: false
};

export interface IContractNumbersByCompanyID {
  ID: number;
  ContractNumber: string;
}

export const contractNumbersByCompanyIDInitialState: IContractNumbersByCompanyID = {
  ID: 0,
  ContractNumber: ""
};

export interface IOrderResponse {
  data: IOrder[] | null;
  count: number;
  currentPage: number;
  lastPage: number;
  companyName: string;
  contractNumber: string;
}