import { FC, ReactNode } from 'react';

import cn from 'classnames';

interface ColProps {
  children: ReactNode;
  className?: string;
}

const Col: FC<ColProps> = ({ children, className }) => {
  return (
    <div className={cn('col', className)}>{children}</div>
  );
};

export default Col;
