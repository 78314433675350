import { useEffect, useState } from "react";

import { SESSION_TYPE } from "@core/constants";

const useStorage = <T,>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    let item: string | null;

    if (window.localStorage.getItem(SESSION_TYPE) === "local") {
      item = window.localStorage.getItem(key);
    } else {
      item = window.sessionStorage.getItem(key);
    }

    return item ? (JSON.parse(item) as T) : initialValue;
  });

  const setValue = (value: T | ((prevState: T) => T)) => {
    setStoredValue(prevState => {
      const valueToStore = value instanceof Function ? value(prevState) : value;
      setStorageItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    });
  };

  useEffect(() => {
    const item = getStorageItem(key);
    if (item) {
      setStoredValue(JSON.parse(item) as T);
    }
  }, [key]);

  return [storedValue, setValue] as const;
};

export const deleteStorageKey = (key: string) => {
  if (window.localStorage.getItem(SESSION_TYPE) === "local") {
    window.localStorage.removeItem(key);
  } else {
    window.sessionStorage.removeItem(key);
  }
};

const getStorageItem = (key: string) => {
  const sessionType = window.localStorage.getItem(SESSION_TYPE);

  if (sessionType === "local") {
    return window.localStorage.getItem(key);
  } else {
    return window.sessionStorage.getItem(key);
  }
};

const setStorageItem = (key: string, value: any) => {
  const sessionType = window.localStorage.getItem(SESSION_TYPE);

  if (sessionType === "local") {
    return window.localStorage.setItem(key, value);
  } else {
    return window.sessionStorage.setItem(key, value);
  }
};

export default useStorage;