import { FC, MouseEvent, useEffect, useId, useState } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Footer from "@components/Footer/Footer";
import LogoHighIcon from "@components/Icons/LogoHighIcon";
import { SIGN_IN_PAGE_LINK } from "@core/constants";
import useSession from "@hooks/useSession";
import useTheme from "@hooks/useThemes";
import useToast from "@hooks/useToast";
import { authService } from "@services/authService";
import { isValidEmail } from "@utils/validators";

import styles from "./ForgotPassword.module.scss";

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { isAuth } = useSession();
  const navigate = useNavigate();
  const id = useId();
  const { theme } = useTheme();
  const { addToast } = useToast();

  const [email, setEmail] = useState("");

  if (isAuth) {
    navigate("/");
  }

  const handleEvent = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      if (isValidEmail(email)) {
        const response = await authService.forgotPassword(email);
        if (response) {
          addToast(response.data.message, "success");
        }
      } else {
        addToast(t("validate-email-error-message"), "warning");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    document.title = t("reset-password-text");
  }, [t]);

  return (
    <>
      <section
        className={cn("vh-100 d-flex flex-column", styles.root, {
          [styles.dark]: theme === "dark",
          [styles.light]: theme === "light"
        })}
      >
        <div
          className="container-fluid h-custom d-flex justify-content-center"
          style={{ flex: 1 }}
        >
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <Link to={SIGN_IN_PAGE_LINK}>
                <LogoHighIcon />
              </Link>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form>
                <h3>{t("reset-password-text")}</h3>
                {/* <p>
                  Введите ваш адрес электронной почты, и мы отправим вам
                  электронное письмо с подробными инструкциями по сбросу пароля.
                </p> */}
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor={`email-${id}`}>
                    {t("label-email")}
                  </label>
                  <input
                    type="email"
                    id={`email-${id}`}
                    className={cn(
                      "form-control form-control-lg",
                      styles["input-field"]
                    )}
                    placeholder={t("enter-email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* {inputEmailError && <span>{inputEmailError}</span>} */}
                </div>

                <div className="d-flex text-center text-lg-start mt-4 pt-2">
                  <button
                    className="btn btn-primary"
                    onClick={handleEvent}
                  >
                    {t("submit-button-text")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default ForgotPassword;
