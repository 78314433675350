import { FC } from 'react';

import cn from 'classnames';

interface PeopleCircleIconProps {
  className?: string;
}

const PeopleCircleIcon: FC<PeopleCircleIconProps> = ({ className }) => {
  return (
    <i className={cn('bi bi-person-circle', className)} />
  );
};

export default PeopleCircleIcon;