import api from "@core/axios";
import { IContract, IContractsWithOrders } from "@interfaces/IContract";

export interface IRawContract {
  contract_id: number;
  contract_number: string;
  is_order_number_exist: boolean;
  date_of_conclusion: string;
  lic_count: number;
  lic_available: number;
  company_id: number;
  created_at: string;
  updated_at: string;
}

type IRawContractResponse = IRawContract[];
type ApiContractResponse = IContract[] | null;

const transformContracts = (rawResponse: IRawContractResponse): ApiContractResponse => {
  return rawResponse.map((item: IRawContract) => ({
    contractID: item.contract_id,
    contractNumber: item.contract_number,
    isOrderNumberExist: item.is_order_number_exist,
    dateOfConclusion: item.date_of_conclusion,
    licCount: item.lic_count,
    licAvailable: item.lic_available,
    companyID: item.company_id,
    createdAt: item.created_at,
    updatedAt: item.updated_at
  }));
};

interface IRawOrder {
  order_id: number;
  order_number: string;
  cr_lic_count: number;
  cr_lic_available: number;
  cr_date_of_conclusion: string;
}

interface IRawContractsWithOrders {
  contract_id: number;
  contract_number: string;
  c_lic_count: number;
  c_lic_available: number;
  c_date_of_conclusion: string;
  orders: IRawOrder[];
}

type IRawContractsWithOrdersResponse = IRawContractsWithOrders[];

type IContractsWithOrdersResponse = IContractsWithOrders[];

const transformContractsWithOrders = (rawResponse: IRawContractsWithOrdersResponse): IContractsWithOrdersResponse => {
  return rawResponse.map((contract) => ({
    ContractID: contract.contract_id,
    ContractNumber: contract.contract_number,
    ContractLicCount: contract.c_lic_count,
    ContractLicAvailable: contract.c_lic_available,
    ContractDateOfConclusion: contract.c_date_of_conclusion,
    Orders: contract.orders.map((order) => ({
      OrderID: order.order_id,
      OrderNumber: order.order_number,
      OrderLicCount: order.cr_lic_count,
      OrderLicAvailable: order.cr_lic_available,
      OrderDateOfConclusion: order.cr_date_of_conclusion
    }))
  }));
};

export const contractService = {
  async getContracts(): Promise<ApiContractResponse> {
    const response = await api.get<IRawContractResponse>("/api/contract/");
    if (response.data) {
      return transformContracts(response.data);
    } else {
      return null;
    }
  },

  async getContractsWithOrders(): Promise<IContractsWithOrdersResponse> {
    const response = await api.get<IRawContractsWithOrdersResponse>("/api/contract/orders");
    return transformContractsWithOrders(response.data);
  }
};
