export interface ICompany {
  id: number;
  name: string;
  users_count: number;
  contract_count: number;
  created_at: string;
}

export const companyInitialValue = {
  id: 0,
  name: "",
  users_count: 0,
  contract_count: 0,
  created_at: ""
};

export interface ICompanyTableData {
  ID: number;
  name: string;
  contractCount: number;
  contractOrderCount: number;
  createdAt: string;
}

export const companyTableInitialState: ICompanyTableData = {
  ID: 0,
  name: "",
  contractCount: 0,
  contractOrderCount: 0,
  createdAt: ""
};

export interface ICompanyTableDataResponse {
  data: ICompanyTableData[];
  count: number;
  lastPage: number;
  currentPage: number;
}

export interface IContractIDAndName {
  contractID: number;
  contractNumber: string;
}

export interface ICompanyAndContract {
  companyID: number;
  companyName: string;
  contracts: IContractIDAndName[];
}

export type ICompaniesAndContract = ICompanyAndContract[]

export const companyAndContractInitialState: ICompanyAndContract = {
  companyID: 0,
  companyName: "",
  contracts: [{
    contractID: 0,
    contractNumber: ""
  }]
};

export interface ICompanyResponse {
  ID: number;
  name: string;
  createdAt: string;
}

export interface ICompaniesResponse {
  data: ICompanyResponse[];
  count: number;
}

export const companiesInitialState: ICompanyResponse[] = [{
  ID: 0,
  name: "",
  createdAt: ""
}];