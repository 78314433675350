import { FC, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PageNavigate from "@components/Navigate/PageNavigate";
import PaginationView from "@components/Pagination/PaginationView";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import {
  CONTRACT_PAGE_LINK,
  getContractLicenseInfoPageLinkByContractNumber,
  getContractLicensesPageLinkByContractID,
  getUserInfoPageLinkByID
} from "@core/constants";
import useToast from "@hooks/useToast";
import { ILicenseWithUser, licenseWithUserInitialState } from "@interfaces/ILicenses";
import { adminService } from "@services/adminService";
import { formatDateSimple, formatDateTime, getDate } from "@utils/date";

const LicensePage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();

  const [data, setData] =
    useState<ILicenseWithUser[]>([licenseWithUserInitialState]);
  const [itemViewCount, setItemViewCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<ILicenseWithUser[]>([licenseWithUserInitialState]);

  const [companyName, setCompanyName] = useState<string>("");
  const [contractNumber, setContractNumber] = useState<string>("");

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  useEffect(() => {
    const filteredTableData = data.filter((val) => {
      return val.licenseNumber
        .toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase());
    });
    setFilteredData(filteredTableData);
  }, [data, searchValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getLicenses(Number(id), currentPage, itemViewCount);

        if (response) {
          setData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
          setCurrentPage(response.currentPage);
          setCompanyName(response.companyName);
          setContractNumber(response.contractNumber);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, [currentPage, itemViewCount, id, addToast]);

  const LicTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>
        <th className="text-center" style={{ width: "180px" }}>Номер лицензии</th>
        <th className="text-center">Срок лицензии</th>
        <th className="text-center">Привязка оборудования</th>
        <th className="text-center">Версия лицензии</th>
        <th className="text-center">Название файла конфигурации (ориг.)</th>
        <th className="text-center">Название файла конфигурации</th>
        <th className="text-center">Название файла лицензии</th>
        <th className="text-center">Выдана</th>
        <th className="text-center">Дата выдачи</th>
        <th className="text-center">Загрузил</th>
      </tr>
    );
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Компания: {companyName}, номер контракта: {contractNumber}</h6>
      </CardHeader>
      <PageNavigate
        current={getContractLicensesPageLinkByContractID(Number(id))}
        to={CONTRACT_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Row>
          <TableViewItem
            itemViewCount={itemViewCount}
            setItemViewCount={setItemViewCount}
          />
          <TableSearchItem
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Row>
        <Row>
          <div className="table-responsive mt-2">
            <table className="table table-striped table-bordered my-0">
              <thead>
              <LicTableHead />
              </thead>
              <tbody>
              {filteredData && filteredData.map((row: ILicenseWithUser, key: number) => (
                <tr key={row.ID}>
                  <td className="align-middle text-center">{getRowNumber(key)}</td>
                  <td className="align-middle text-center">
                    <Link to={getContractLicenseInfoPageLinkByContractNumber(Number(id), row.licenseNumber)}>
                      {row.licenseNumber}
                    </Link>
                  </td>

                  <td className="align-middle text-center">
                    {getDate(row.expirationDate) === "31.12.9999" ? "бессрочно" : formatDateSimple(row.expirationDate)}
                  </td>

                  <td className="align-middle text-center">
                    <div
                      className={`alert ${
                        row.hwFix ? "alert-danger" : "alert-success"
                      }  text-center p-0 m-0`}>
                      {row.hwFix ? "Да" : "Нет"}
                    </div>
                  </td>

                  <td className="align-middle text-center">{row.licVersion}</td>
                  <td
                    className="align-middle text-center">{row.ccbOldFileName ? row.ccbOldFileName : "Отсутствует"}</td>
                  <td className="align-middle text-center">{row.ccbFileName ? row.ccbFileName : "Отсутствует"}</td>
                  <td className="align-middle text-center">{row.licFileName ? row.licFileName : "Отсутствует"}</td>

                  <td className="align-middle text-center">
                    <div
                      className={`alert ${
                        row.issueComplete ? "alert-success" : "alert-danger"
                      }  text-center p-0 m-0`}>
                      {row.issueComplete ? "Да" : "Нет"}
                    </div>
                  </td>
                  <td
                    className="align-middle text-center">{row.issueDate ? formatDateTime(row.issueDate) : "Отсутствует"}</td>
                  <td

                    className="align-middle text-center">{(row.userName && row.userSurname) ?
                    <Link to={getUserInfoPageLinkByID(row.userID)}>
                      {row.userName + " " + row.userSurname}
                    </Link>
                    : "Отсутствует"}</td>
                </tr>
              ))}
              </tbody>
              <tfoot>
              <LicTableHead />
              </tfoot>
            </table>
          </div>
        </Row>
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};
export default LicensePage;