import api, { apiAdmin } from "@core/axios";
import { ILicense } from "@interfaces/ILicenses";

export interface ApiLicenseResponse {
  count: number;
  currentPage: number;
  lastPage: number;
  data: ILicense[];
}

export interface IRawPlugin {
  smtcp: boolean;
  opc_ua: boolean;
  modbus: boolean;
  redundancy: boolean;
  elna: boolean;
  online_transfer: boolean;
  archiver: boolean;
}

export interface IRawLicense {
  id: number;
  license_number: string;
  date_of_issue: string;
  expiration_date: string;
  lic_version: number;
  contract_id: number;
  plugin_id: number;
  lic_file_name: string | null;
  ccb_old_file_name: string | null;
  ccb_file_name: string | null;
  loaded_ccb: boolean;
  created_at: string;
  issue_complete: boolean;
  issue_date: string | null;
  description: string | null;
  plugins: IRawPlugin;
}

export interface IRawApiResponse {
  count: number;
  currentPage: number;
  lastPage: number;
  data: IRawLicense[];
}

const transformLicenses = (rawResponse: IRawApiResponse): ApiLicenseResponse => {
  const data = rawResponse.data.map(rawLicense => ({
    ID: rawLicense.id,
    licenseNumber: rawLicense.license_number,
    dateOfIssue: rawLicense.date_of_issue,
    expirationDate: rawLicense.expiration_date,
    licVersion: rawLicense.lic_version,
    contractID: rawLicense.contract_id,
    pluginID: rawLicense.plugin_id,
    licFileName: rawLicense.lic_file_name,
    ccbOldFileName: rawLicense.ccb_old_file_name,
    ccbFileName: rawLicense.ccb_file_name,
    loadedCcb: rawLicense.loaded_ccb,
    createdAt: rawLicense.created_at,
    issueComplete: rawLicense.issue_complete,
    issueDate: rawLicense.issue_date,
    description: rawLicense.description,
    plugins: {
      smtcp: rawLicense.plugins.smtcp,
      opcUa: rawLicense.plugins.opc_ua,
      modbus: rawLicense.plugins.modbus,
      redundancy: rawLicense.plugins.redundancy,
      elna: rawLicense.plugins.elna,
      onlineTransfer: rawLicense.plugins.online_transfer,
      archiver: rawLicense.plugins.archiver
    }
  }));

  return <ApiLicenseResponse>{
    count: rawResponse.count,
    currentPage: rawResponse.currentPage,
    lastPage: rawResponse.lastPage,
    data: data
  };
};

export interface ApiUploadSingleLicenseResponse {
  ID: number;
  loadedCcb: boolean;
  oldCcbFileName: string;
  urlCcbFile: string;
  urlLicFile: string;
}

export interface IRawApiUploadSingleLicenseResponse {
  status: string;
  id: number;
  loaded_ccb: boolean;
  old_ccb_file_name: string;
  url_ccb_file: string;
  url_lic_file: string;
}

const transformUploadSingleFile = (rawResponse: IRawApiUploadSingleLicenseResponse): ApiUploadSingleLicenseResponse => {
  return {
    ID: rawResponse.id,
    loadedCcb: rawResponse.loaded_ccb,
    oldCcbFileName: rawResponse.old_ccb_file_name,
    urlCcbFile: rawResponse.url_ccb_file,
    urlLicFile: rawResponse.url_lic_file
  };
};

export const licenseService = {
  async getLicenses(contractID: number, page: number, limit: number): Promise<ApiLicenseResponse> {
    const response = await api.get<IRawApiResponse>(`/api/licenses/`, {
      params: {
        page: page,
        limit: limit,
        id: contractID
      }
    });

    return transformLicenses(response.data);
  },
  async uploadSingleFile(formData: any): Promise<ApiUploadSingleLicenseResponse> {
    const response = await api.post<IRawApiUploadSingleLicenseResponse>("/api/file/single", formData);

    return transformUploadSingleFile(response.data);
  },
  async uploadZipArchive(formData: any) {
    return await api.post("/api/file/archive", formData);
  },

  async generateSingleLicense(formData: FormData) {
    return await apiAdmin.post("/api/license/single/", formData);
  },

  async updateLicenseDescription(id: number, text: string) {
    return await api.patch(`/api/licenses/${id}`, {
      "description": text
    });
  }

};
