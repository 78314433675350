import api, { fileApi } from "@core/axios";
import { ISoftware, ISoftwareApplication, ISoftwareVersion } from "@interfaces/ISoftware";
import { IUserAuth } from "@interfaces/IUser";

interface IRawSoftwareVersion {
  id: number;
  name: string;
  version: string;
  release_date: string;
  is_active: boolean;
  is_full_release: boolean;
  description: string;
  operation_system_name: string;
  change_log: string;
  file_name: string;
  file_size: number;
  file_type: string;
  file_crc_32: number;
  file_sha256: string;
  created_at: string;
}

interface IRawSoftware {
  id: number;
  name: string;
  description: string;
}

type IRawSoftwareResponse = IRawSoftware[];
type ApiSoftwareResponse = ISoftware[];

const transformSoftware = (rawResponse: IRawSoftwareResponse): ApiSoftwareResponse => {
  return rawResponse.map((item: IRawSoftware) => ({
    ID: item.id,
    name: item.name,
    description: item.description
  }));
};

type IRawSoftwareVersionResponse = IRawSoftwareVersion[];
type ApiSoftwareVersionResponse = ISoftwareVersion[] | null;

const transformSoftwareVersions = (rawResponse: IRawSoftwareVersionResponse): ApiSoftwareVersionResponse => {
  if (rawResponse === null)
    return null;

  return rawResponse.map((item: IRawSoftwareVersion) => ({
    ID: item.id,
    name: item.name,
    version: item.version,
    releaseDate: item.release_date,
    isActive: item.is_active,
    isFullRelease: item.is_full_release,
    description: item.description,
    operationSystemName: item.operation_system_name,
    changeLog: item.change_log,
    fileName: item.file_name,
    fileSize: item.file_size,
    fileType: item.file_type,
    fileCrc32: item.file_crc_32,
    fileSha256: item.file_sha256,
    createdAt: item.created_at
  }));
};

interface IRawSoftwareApplication {
  id: number;
  software_id: number;
  name: string;
  description: string;
}

type IRawSoftwareApplicationResponse = IRawSoftwareApplication[];
type ApiSoftwareApplicationResponse = ISoftwareApplication[];

const transformSoftwareApplication = (rawResponse: IRawSoftwareApplicationResponse): ApiSoftwareApplicationResponse => {
  return rawResponse.map((item: IRawSoftwareApplication) => ({
    ID: item.id,
    softwareID: item.software_id,
    name: item.name,
    description: item.description
  }));
};

interface IRawUserAuth {
  id: number;
  email: string;
  name: string;
  surname: string;
  access_level: number;
  company_id: number;
  company_name: string;
  role_name: string;
}

const transformUserAuthData = (rawResponse: IRawUserAuth): IUserAuth => {
  return {
    id: rawResponse.id,
    email: rawResponse.email,
    name: rawResponse.name,
    surname: rawResponse.surname,
    accessLevel: rawResponse.access_level,
    companyID: rawResponse.company_id,
    companyName: rawResponse.company_name,
    roleName: rawResponse.role_name
  };
};

export const userService = {
  async getProfile(): Promise<IUserAuth> {
    const response = await api.get<IRawUserAuth>(`/api/users/profile`);
    return transformUserAuthData(response.data);
  },

  async changePassword(oldPassword: string, password: string) {
    return await api.patch("/api/users/password/change", { oldPassword, password });
  },

  async getSoftware(): Promise<ApiSoftwareResponse> {
    const response = await api.get<IRawSoftwareResponse>("/api/software/");

    return transformSoftware(response.data);
  },

  async getSoftwareApplication(): Promise<ApiSoftwareApplicationResponse> {
    const response = await api.get<IRawSoftwareApplicationResponse>("/api/software/application");

    return transformSoftwareApplication(response.data);
  },

  async getSoftwareVersions(osID: number, softAppID: number): Promise<ApiSoftwareVersionResponse> {
    const response = await api.get<IRawSoftwareVersionResponse>(`/api/software/versions`, {
      params: {
        os_id: osID,
        soft_app_id: softAppID
      }
    });

    return transformSoftwareVersions(response.data);
  },

  async handleDownloadClick(downloadUrl: string) {
    return await fileApi.get(downloadUrl, {
      responseType: "blob"
    });
  },

  async generateShortLink(fileName: string) {
    return await fileApi.get(`/download/software/link/${fileName}`);
  }
};
