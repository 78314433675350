import React, { FC } from "react";

import cn from "classnames";

import logoHigh from "@assets/logoHigh.png";
import logoHighNewYear from "@assets/logoHighNewYear.png";
import yellowLogoHigh from "@assets/yellowLogoHigh.png";
import yellowLogoHighNewYear from "@assets/yellowLogoHighNewYear.png";
import useTheme from "@hooks/useThemes";
import { isHolidaySeason } from "@utils/holidays";

interface LogoHighIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string | undefined;
}

const LogoHighIcon: FC<LogoHighIconProps> = ({ className, ...props }) => {
  const { theme } = useTheme();

  const logoImagePath = isHolidaySeason() ? logoHighNewYear : logoHigh;
  const yellowLogoImagePath = isHolidaySeason() ? yellowLogoHighNewYear : yellowLogoHigh;

  return (
    <img
      src={theme === "light" ? logoImagePath : yellowLogoImagePath}
      className={cn("img-fluid", className)}
      alt="logo"
      {...props}
    />
  );
};

export default LogoHighIcon;