import React, { FC } from "react";

import logo from "@assets/logo.png";
import logoNewYear from "@assets/logoNewYear.png";
import yellowLogo from "@assets/yellowLogo.png";
import yellowLogoNewYear from "@assets/yellowLogoNewYear.png";
import useTheme from "@hooks/useThemes";
import { isHolidaySeason } from "@utils/holidays";

interface LogoIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string | undefined;
}

const LogoIcon: FC<LogoIconProps> = ({ className, ...props }) => {
  const { theme } = useTheme();

  const logoImagePath = isHolidaySeason() ? logoNewYear : logo;
  const yellowLogoImagePath = isHolidaySeason() ? yellowLogoNewYear : yellowLogo;

  return (
    <img
      src={theme === "light" ? logoImagePath : yellowLogoImagePath}
      className={className}
      alt="logo"
      {...props}
    />
  );
};

export default LogoIcon;