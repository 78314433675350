import { FC, useEffect, useState } from "react";

import snowman from "@assets/snowman.png";

const SnowmanIcon: FC = () => {
  const [isWideScreen, setIsWideScreen] = useState<boolean>(window.innerWidth >= 1880);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1880);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isWideScreen && (
        <img src={snowman} alt="snowman" style={{ position: "fixed", left: 0, bottom: 0 }} />
      )}
    </>
  );
};

export default SnowmanIcon;