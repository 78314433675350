import { FC, useEffect, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import DropdownWithCheckboxes from "@components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import PaginationView from "@components/Pagination/PaginationView";
import Row from "@components/Wrapper/Row";
import { CONTRACT_CREATE_PAGE_LINK, CONTRACT_ORDER_CREATE_PAGE_LINK, CONTRACT_PAGE_LINK } from "@core/constants";
import useToast from "@hooks/useToast";
import { companiesInitialState, ICompanyResponse } from "@interfaces/ICompany";
import { IContractsWithCompany } from "@interfaces/IContract";
import TableData from "@pages/Admins/Contract/ContractPage/Components/TableData";
import { adminService } from "@services/adminService";
import { queryStringBuilder } from "@utils/query";

const ContractPage: FC = () => {
  const { addToast } = useToast();

  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();

  const [itemViewCount, setItemViewCount] = useState(10);
  const [tableData, setTableData] = useState<IContractsWithCompany[]>([]);
  const [oldTableData, setOldTableData] = useState<IContractsWithCompany[]>([]);

  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [checkedCompanies, setCheckedCompanies] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getContractWithCompanies(search.toString());
        if (response.data !== null) {
          setTableData(response.data);
          setOldTableData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
        } else {
          setTableData([]);
          setOldTableData([]);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [addToast, search]);

  useEffect(() => {
    const queryString = queryStringBuilder({
      limit: itemViewCount,
      page: currentPage,
      companies: checkedCompanies
    });

    navigate(queryString);
  }, [itemViewCount, currentPage, checkedCompanies, navigate]);

  useEffect(() => {
    const _companies = search.get("companies");
    if (_companies) {
      setCheckedCompanies(_companies.split(","));
    }

    const _itemViewCount = search.get("limit");
    if (_itemViewCount && Number(_itemViewCount) > 0 && Number(_itemViewCount) <= 100) {
      setItemViewCount(Number(_itemViewCount));
    }

    const _page = search.get("page");
    if (_page) {
      if (_page && Number(_page) > 0 && Number(_page)) {
        setCurrentPage(Number(_page));
      }
    }
  }, []);

  const handleSwitchCreateContractPage = () => {
    if (location.pathname === CONTRACT_PAGE_LINK) {
      navigate(CONTRACT_CREATE_PAGE_LINK);
    } else {
      navigate(CONTRACT_PAGE_LINK);
    }
  };

  const handleSwitchCreateContractOrderPage = () => {
    if (location.pathname === CONTRACT_PAGE_LINK) {
      navigate(CONTRACT_ORDER_CREATE_PAGE_LINK);
    } else {
      navigate(CONTRACT_PAGE_LINK);
    }
  };

  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [companies, setCompanies] = useState<ICompanyResponse[]>(companiesInitialState);
  const handleDropdownClick = async (e: any) => {
    try {
      if (!isFirstOpen) {
        const res = await adminService.getCompany();
        if (res) {
          setCompanies(res.data);
          setIsFirstOpen(true);
        }
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }

  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Контракты</h6>
      </CardHeader>
      <Row>
        <div className="col-12">
          <button
            type="submit"
            onClick={handleSwitchCreateContractPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Добавить контракт
          </button>
          <button
            type="submit"
            onClick={handleSwitchCreateContractOrderPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Добавить заявку к контракту
          </button>
          <hr />
        </div>
      </Row>
      <CardBody>
        <div className="d-flex" onClick={handleDropdownClick}>
          <DropdownWithCheckboxes
            title={"Компания"}
            options={companies.map((val: ICompanyResponse) => {
              return val.name;
            })}
            checkedOptions={checkedCompanies}
            setCheckedOptions={setCheckedCompanies}
          />
        </div>
        <TableData
          tableData={tableData}
          setTableData={setTableData}
          oldTableData={oldTableData}
          setOldTableData={setOldTableData}
          itemViewCount={itemViewCount}
          setItemViewCount={setItemViewCount}
          currentPage={currentPage}
        />
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default ContractPage;
