export interface IRole {
  ID: number;
  accessLevel: number;
  name: string;
}

export const roleInitialState = {
  ID: 0,
  accessLevel: 0,
  name: ''
};
