import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getDateWithTime } from '@utils/date';

interface IssueDateProps {
  issueDate?: string | null;
}

const IssueDate: FC<IssueDateProps> = ({ issueDate }) => {
  const { t } = useTranslation();

  return (
    <td className={'align-middle text-center'}>
      {issueDate ? getDateWithTime(issueDate) : t('absent-text')}
    </td>
  );
};

export default IssueDate;