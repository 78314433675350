import  { ChangeEvent, Dispatch, FC } from 'react';

interface VersionProgramInputProps {
  versionProgram: string;
  setVersionProgram: Dispatch<string>
}

const VersionProgramInput: FC<VersionProgramInputProps> = ({versionProgram, setVersionProgram}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVersionProgram(e.target.value);
  };

  return (
    <div className="col-12">
      <label>Версия ПО</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-mailbox"></i>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Введите версию ПО"
          name="name"
          onChange={handleChange}
          value={versionProgram}
        />
      </div>
    </div>
  );
};

export default VersionProgramInput;