import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";

import {
  AUDIT_PAGE_LINK,
  COMPANIES_PAGE_LINK,
  CONTRACT_PAGE_LINK,
  GENERATE_LICENSE_PAGE_LINK,
  MAIN_PAGE_LINK,
  SOFTWARE_PAGE_LINK,
  USERS_PAGE_LINK
} from "@core/constants";
import AdminAccessWrapper from "@permission/AccessControlWrapper";

import styles from "./Sidebar.module.scss";

import BookHalfIcon from "../Icons/BookHalfIcon";
import BuildingsIcon from "../Icons/BuildingsIcon";
import CloudUploadFillIcon from "../Icons/CloudUploadFillIcon";
import GearWideIcon from "../Icons/GearWideIcon";
import PeopleIcon from "../Icons/PeopleIcon";

const Sidebar = () => {
  const currentPage = useLocation().pathname;

  return (
    <div className="bg-primary">
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 83px)" }}
      >

        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to={MAIN_PAGE_LINK}
              className={cn(
                "nav-link",
                styles.nav_link,
                currentPage === MAIN_PAGE_LINK ? styles.active : styles.default)
              }
            >
              <i
                className={cn("fs-4 bi-house align-middle",
                  currentPage === MAIN_PAGE_LINK
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage === MAIN_PAGE_LINK
                    ? "text-secondary" : "text-white")}
              >
                Главная страница
              </span>
            </NavLink>
            <hr className={styles.hr} />
          </li>

          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to={COMPANIES_PAGE_LINK}
              className={cn(
                "nav-link",
                styles.nav_link,
                currentPage.startsWith(COMPANIES_PAGE_LINK) ? styles.active : styles.default)
              }
            >
              <BuildingsIcon
                className={cn("align-middle",
                  currentPage.startsWith(COMPANIES_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(COMPANIES_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Компании
              </span>
            </NavLink>
            <hr className={styles.hr} />
          </li>

          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to={CONTRACT_PAGE_LINK}
              className={cn(
                "nav-link",
                styles.nav_link,
                currentPage.startsWith(CONTRACT_PAGE_LINK) ? styles.active : styles.default)
              }
            >
              <BookHalfIcon
                className={cn("align-middle",
                  currentPage.startsWith(CONTRACT_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(CONTRACT_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Контракты
              </span>
            </NavLink>
            <hr className={styles.hr} />
          </li>

          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to={USERS_PAGE_LINK}
              className={cn(
                "nav-link",
                styles.nav_link,
                currentPage.startsWith(USERS_PAGE_LINK) ? styles.active : styles.default)
              }
            >
              <PeopleIcon
                className={cn("align-middle",
                  currentPage.startsWith(USERS_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(USERS_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                Пользователи
              </span>
            </NavLink>
            <hr className={styles.hr} />
          </li>

          <li className="nav-item" style={{ width: "100%" }}>
            <NavLink
              to={SOFTWARE_PAGE_LINK}
              className={cn(
                "nav-link",
                styles.nav_link,
                currentPage.startsWith(SOFTWARE_PAGE_LINK) ? styles.active : styles.default)
              }
            >
              <CloudUploadFillIcon
                className={cn("align-middle",
                  currentPage.startsWith(SOFTWARE_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              />
              <span
                className={cn("ms-1 d-none d-sm-inline align-middle",
                  currentPage.startsWith(SOFTWARE_PAGE_LINK)
                    ? "text-secondary" : "text-white")}
              >
                 Загруженное ПО
              </span>
            </NavLink>
            <hr className={styles.hr} />
          </li>

          <AdminAccessWrapper>
            <li className="nav-item" style={{ width: "100%" }}>
              <NavLink
                to={GENERATE_LICENSE_PAGE_LINK}
                className={cn(
                  "nav-link",
                  styles.nav_link,
                  currentPage.startsWith(GENERATE_LICENSE_PAGE_LINK) ? styles.active : styles.default)
                }
              >
                <GearWideIcon
                  className={cn("align-middle",
                    currentPage === GENERATE_LICENSE_PAGE_LINK
                      ? "text-secondary" : "text-white")}
                />
                <span
                  className={cn("ms-1 d-none d-sm-inline align-middle",
                    currentPage === GENERATE_LICENSE_PAGE_LINK
                      ? "text-secondary" : "text-white")}
                >
                Сгенерировать лицензию
              </span>
              </NavLink>
              <hr className={styles.hr} />
            </li>

            <li className="nav-item" style={{ width: "100%" }}>
              <NavLink
                to={AUDIT_PAGE_LINK}
                className={cn(
                  "nav-link",
                  styles.nav_link,
                  currentPage.startsWith(AUDIT_PAGE_LINK) ? styles.active : styles.default)
                }
              >
                <i className={cn("fs-4 bi bi-file-earmark-ruled align-middle",
                  currentPage === AUDIT_PAGE_LINK
                    ? "text-secondary" : "text-white")}
                />
                <span
                  className={cn("ms-1 d-none d-sm-inline align-middle",
                    currentPage === AUDIT_PAGE_LINK
                      ? "text-secondary" : "text-white")}
                >
                Аудит
              </span>
              </NavLink>
              <hr className={styles.hr} />
            </li>
          </AdminAccessWrapper>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
