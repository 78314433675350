import { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

interface NavigateProps {
  title: string;
  current: string;
  to: string;
}

const PageNavigate: FC<NavigateProps> = ({ title, current, to }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSwitchPage = () => {
    if (decodeURIComponent(location.pathname) === current) {
      navigate(to);
    } else {
      navigate(current);
    }
  };

  return (
    <div className="col-12">
      <button
        type="submit"
        onClick={handleSwitchPage}
        className="btn btn-primary btn-sm px-4 mt-4 ms-4"
        style={{ marginLeft: 'auto' }}
      >
        {title}
      </button>
      <hr />
    </div>
  );
};

export default PageNavigate;