import { ReactNode, Suspense } from "react";

import { Navigate } from "react-router-dom";

import { MAIN_PAGE_LINK } from "@core/constants";
import useSession from "@hooks/useSession";

type Props = {
  redirectTo?: string;
  permissions?: number[];
  children: ReactNode;
};

const PrivateRoute = (props: Props) => {
  const { redirectTo, permissions, children } = props;
  const { isAuth, user, loadingUserData } = useSession();

  if (loadingUserData) {
    return null;
  }

  if (!isAuth) {
    // @ts-ignore
    return <Navigate to={redirectTo} />;
  }

  if (permissions) {
    let hasPermission = false;
    let adminRole = user.accessLevel;
    if (adminRole) {
      hasPermission = permissions.includes(adminRole);
    }

    if (!hasPermission) {
      return <Navigate to={MAIN_PAGE_LINK} />;
    }
  }
  return (
    <Suspense>{children}</Suspense>
  );
};

export default PrivateRoute;