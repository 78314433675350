import { FC, useEffect, useState } from "react";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PageNavigate from "@components/Navigate/PageNavigate";
import Col from "@components/Wrapper/Col/Col";
import Container from "@components/Wrapper/Container";
import Row from "@components/Wrapper/Row";
import { COMPANIES_CREATE_PAGE_LINK, COMPANIES_PAGE_LINK } from "@core/constants";
import useToast from "@hooks/useToast";
import { adminService } from "@services/adminService";

const CreateCompanyPage: FC = () => {
  const { addToast } = useToast();

  const [companyName, setCompanyName] = useState("");
  const [isCheckedSubmitButton, setIsCheckedSubmitButton] =
    useState<boolean>(true);

  const handleCompanyNameChange = (e: any) => {
    setCompanyName(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const data = {
      name: companyName
    };

    try {
      const response = await adminService.createCompany(data);
      if (response.data) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    if (companyName !== "") {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [companyName]);

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Добавить компанию</h6>
      </CardHeader>
      <PageNavigate
        current={COMPANIES_CREATE_PAGE_LINK}
        to={COMPANIES_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Container>
          <Row>
            <Col>
              <div className="form-left h-100 py-5 px-5">
                <form className="row g-4">
                  <div className="col-12">
                    <label>Название</label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-mailbox"></i>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Введите название компании"
                        onChange={handleCompanyNameChange}
                        value={companyName}
                      />
                    </div>
                  </div>

                  <div className="d-flex col-12">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary px-4 float-end mt-4"
                      disabled={isCheckedSubmitButton}
                      style={{ marginLeft: "auto" }}
                    >
                      Создать
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default CreateCompanyPage;
