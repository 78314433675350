import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import logoHigh from "@assets/logoHigh.png";
import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import LogoHighIcon from "@components/Icons/LogoHighIcon";
import ColMd6 from "@components/Wrapper/Col/ColMd6";
import useTheme from "@hooks/useThemes";
import useToast from "@hooks/useToast";
import { contractInitialState, IContract } from "@interfaces/IContract";
import { ILicense, licenseInitialState } from "@interfaces/ILicenses";
import ContractInfo from "@module/MainPage/ContractInfo";
import SelectContract from "@module/MainPage/SelectContract";
import LicensesTable from "@module/MainPage/Table/LicensesTable";
import UploadFile from "@module/MainPage/UploadFile";
import { contractService } from "@services/contractService";
import { licenseService } from "@services/licenseService";

const ContractIsNotExist = () => {
  return (

    <Card className="mt-3" style={{ minHeight: "calc(100vh - 102px - 73px)" }}>
      <div className="container-fluid h-custom d-flex justify-content-center">
        <div className="row d-flex justify-content-center align-items-center h-100 mt-5">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <LogoHighIcon width={500}/>
          </div>
          <div className="ms-3 col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <h3>К сожалению, у вашей компании отсутствуют контракты</h3>
          </div>
        </div>
      </div>
    </Card>
  );
};

const MainPage: FC = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { addToast } = useToast();

  const [data, setData] = useState<ILicense[]>([licenseInitialState]);
  const [contracts, setContracts] = useState<IContract[]>([
    contractInitialState
  ]);
  const [isContractExist, setIsContractExist] = useState<boolean>(false);

  const [contractID, setContractID] = useState<number>(0);
  const [contractNumber, setContractNumber] = useState<string>("");
  const [selectedContract, setSelectedContract] =
    useState<IContract>(contractInitialState);

  const [itemViewCount, setItemViewCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(0);
  const [isLicExist, setIsLicExist] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (contractID !== 0) {
        try {
          const response = await licenseService.getLicenses(contractID, currentPage, itemViewCount);
          setIsLicExist(true);

          if (response) {
            setData(response.data);
            setItemCount(response.count);
            setLastPage(response.lastPage);
            setCurrentPage(response.currentPage);
          }
        } catch (e: any) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    };

    fetchData();
  }, [contractID, currentPage, itemViewCount, isLicExist, addToast]);

  useEffect(() => {
    document.title = "Главная страница";

    const fetchData = async () => {
      try {
        const responseContracts = await contractService.getContracts();
        if (responseContracts) {
          setIsContractExist(true);
          setContracts(responseContracts);
          setContractNumber(responseContracts[0].contractNumber);
          setContractID(responseContracts[0].contractID);
        } else {
          setIsContractExist(false);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {isContractExist ? (
        <Card className="mt-3">
          <CardHeader className="py-3">
            <p className="m-0 fw-bold">{t("licenses-text")}</p>
          </CardHeader>
          <CardBody>
            <ColMd6 className="text-nowrap">
              <SelectContract
                selectedContract={selectedContract}
                setSelectedContract={setSelectedContract}
                contracts={contracts}
                setContractNumber={setContractNumber}
                setContractID={setContractID}
                contractNumber={contractNumber}
              />
              <ContractInfo selectedContract={selectedContract} />
            </ColMd6>
            <UploadFile
              contractID={contractID}
              data={data}
              setData={setData}
            />
            <hr style={{ borderColor: theme === "light" ? "#000000" : "#ffffff" }} />
            <LicensesTable
              contractID={contractID}
              data={data}
              setData={setData}
              itemViewCount={itemViewCount}
              setItemViewCount={setItemViewCount}
              isLicExist={isLicExist}
              currentPage={currentPage}
              itemCount={itemCount}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      ) : (
        <ContractIsNotExist />
      )}
    </>
  );
};

export default MainPage;
