import { createContext, Dispatch, SetStateAction } from "react";

export type FallingStarsContextType = {
  isFallingStars: boolean;
  setIsFallingStars: Dispatch<SetStateAction<boolean>>;
};

const FallingStarsContext = createContext({} as FallingStarsContextType);

export default FallingStarsContext;
