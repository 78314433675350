import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import PencilSquare from "@components/Icons/PencilSquare";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import {
  getCompanyContractOrdersPageByCompanyIDAndContractID,
  getCompanyPageByID,
  getContractLicensesPageLinkByContractID, getContractOrdersPageByContractID,
  STATUS_CREATED
} from "@core/constants";
import useToast from "@hooks/useToast";
import { IContractsWithCompany } from "@interfaces/IContract";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import { formatDateSimple, formatDateTime } from "@utils/date";
import { compareRows } from "@utils/utils";

interface TableDataProps {
  tableData: IContractsWithCompany[];
  setTableData: Dispatch<SetStateAction<IContractsWithCompany[]>>;
  oldTableData: IContractsWithCompany[];
  setOldTableData: Dispatch<SetStateAction<IContractsWithCompany[]>>;
  itemViewCount: number;
  setItemViewCount: Dispatch<SetStateAction<number>>;
  currentPage: number;
}

const TableData: FC<TableDataProps> = ({
                                         tableData,
                                         setTableData,
                                         oldTableData,
                                         setOldTableData,
                                         itemViewCount,
                                         setItemViewCount,
                                         currentPage
                                       }) => {
  const { addToast } = useToast();

  const [searchValue, setSearchValue] = useState("");

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [rowIDToEdit, setRowIDToEdit] = useState<number | undefined>(undefined);
  const [isFirstEditRowID, setIsFirstEditRowID] = useState<number>(-1);

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  const filteredTableData = tableData.filter((val) => {
    return val.companyName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
  });

  const ContractTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>
        <AdminAccessWrapper>
          <th className="text-center">Действие</th>
        </AdminAccessWrapper>
        <th className="text-center">Название компании</th>
        <th className="text-center">Номер контракта</th>
        <th className="text-center">Количество заявок</th>
        <th className="text-center">Количество лицензий</th>
        <th className="text-center">Лицензий осталось</th>
        <th className="text-center">Экспорт номеров</th>
        <th className="text-center">Дата заключения контракта</th>
        <th className="text-center">Дата создания</th>
      </tr>
    );
  };

  const EmptyRow = () => {
    return (
      <tr>
        <td className="align-middle text-center"></td>
        <AdminAccessWrapper>
          <td className="align-middle text-center"></td>
        </AdminAccessWrapper>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
        <td className="align-middle text-center"></td>
      </tr>
    );
  };

  const handleButtonExport = async (contractID: number) => {
    try {
      const response = await adminService.exportLicensesNumberByContractID(contractID);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "license_numbers_contract_id_" + String(contractID);

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    if (rowIDToEdit && rowIDToEdit !== isFirstEditRowID) {
      setIsFirstEditRowID(rowIDToEdit);
      setTableData(oldTableData);
    }
  }, [rowIDToEdit, isFirstEditRowID, oldTableData]);

  const handleEditButtonChange = (rowID: number) => {
    setIsEditMode(true);
    setRowIDToEdit(rowID);
  };

  const handleCloseEditButtonChange = () => {
    setIsEditMode(false);
    setTableData(oldTableData);
  };

  const handleOnChangeField = (e: any, rowID: number) => {
    let value: string;

    const { name: fieldName } = e.target;

    if (fieldName === "isFullRelease" || fieldName === "isActive") {
      value = e.target.options[e.target.selectedIndex].text;
    } else {
      value = e.target.value;
    }

    setTableData(prevData =>
      prevData.map(row =>
        row.contractID === rowID
          ? { ...row, [fieldName]: value }
          : row
      )
    );
  };

  const handleSaveRowChanges = async () => {
    setIsEditMode(false);
    const row = tableData.find(row => row.contractID === rowIDToEdit);
    const oldRow = oldTableData.find(row => row.contractID === rowIDToEdit);
    const changedFields = compareRows(oldRow, row);
    const formData = {};

    if (changedFields.contractNumber) {
      Object.assign(formData, { contractNumber: changedFields.contractNumber });
    }

    if (!(Object.entries(formData).length === 0) && rowIDToEdit) {
      try {
        const response = await adminService.updateContractTableRow(rowIDToEdit, formData);
        if (response.status === STATUS_CREATED) {
          setOldTableData(tableData);
          addToast(response.data.message, "success");
        }
      } catch (e: any) {
        setTableData(oldTableData);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };

  return (
    <Fragment>
      <Row className="mt-3">
        <TableViewItem
          itemViewCount={itemViewCount}
          setItemViewCount={setItemViewCount}
        />
        <TableSearchItem
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Row>
      <div
        className="table-responsive table mt-2"
        id="dataTable"
        role="grid"
        aria-describedby="dataTable_info">
        <table className="table table-striped table-bordered my-0" id="dataTable">
          <thead>
          <ContractTableHead />
          </thead>
          <tbody>
          {filteredTableData.length > 0 ? filteredTableData.map((row, key: number) => (
            <tr key={row.contractID}>
              <td className="align-middle text-center">
                {getRowNumber(key)}
              </td>

              <AdminAccessWrapper>
                <td className="align-middle text-center">
                  {isEditMode && rowIDToEdit === row.contractID ? (
                    <>
                      <button style={{ all: "unset" }} onClick={handleSaveRowChanges}>
                        <i className="fs-5 bi bi-save me-2" />
                      </button>
                      <button style={{ all: "unset" }}
                              onClick={() => handleCloseEditButtonChange()}>
                        <PencilSquare className="fs-5 text-danger" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button style={{ all: "unset" }} onClick={() => handleEditButtonChange(row.contractID)}>
                        <i className="fs-5 bi bi-pencil-square me-2" />
                      </button>
                    </>
                  )}
                </td>
              </AdminAccessWrapper>

              <td className="align-middle text-center" style={{ width: "200px" }}>
                {row.companyName}
              </td>

              {isEditMode && rowIDToEdit === row.contractID ? (
                <td className={"align-middle"}>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="contractNumber"
                    name="contractNumber"
                    onChange={(e) => handleOnChangeField(e, row.contractID)}
                    value={row.contractNumber}
                  />
                </td>
              ) : (
                <td className="align-middle text-center">
                  <Link to={getContractLicensesPageLinkByContractID(row.contractID)}>
                    {row.contractNumber}
                  </Link></td>
              )}

              <td className="align-middle text-center">
                {row.ordersCount > 0 ? (
                  <Link to={getContractOrdersPageByContractID(row.contractID)}>
                    {row.ordersCount}
                  </Link>
                ) : (
                  row.ordersCount
                )}
              </td>

              <td className="align-middle text-center">{row.licCount}</td>
              <td className="align-middle text-center">{row.licAvailable}</td>
              <td className="align-middle text-center" style={{ width: "100px" }}>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleButtonExport(row.contractID)}
                >
                  Скачать
                </button>
              </td>
              <td style={{ width: "180px", verticalAlign: "middle" }} className="align-middle text-center">
                {formatDateSimple(row.dateOfConclusion)}
              </td>
              <td style={{ width: "200px", verticalAlign: "middle" }} className="align-middle text-center">
                {formatDateTime(row.createdAt)}
              </td>
            </tr>
          )) : (
            <EmptyRow />
          )}
          </tbody>
          <tfoot>
          <ContractTableHead />
          </tfoot>
        </table>
      </div>
    </Fragment>
  );
};

export default TableData;