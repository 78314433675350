import { Dispatch, FC, ReactNode, SetStateAction } from "react";

interface ModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  onSaveChange: (e: any) => void;
}

const Modal: FC<ModalProps> = ({ showModal, setShowModal, children, onSaveChange }) => {
  const handleClose = () => setShowModal(false);

  return (
    <>
      {showModal && (
        <div
          className="modal show"
          style={{ display: "block", background: "rgba(224,224,207,0.5)" }}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ position: "relative" }}>
              <div className="modal-header">
                <h5 className="modal-title">Сбросить лицензию</h5>
                <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {children}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                  Закрыть
                </button>
                <button type="button" className="btn btn-primary" onClick={onSaveChange}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;