import { FC, useContext, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import DropdownWithCheckboxes from "@components/DropdownWithCheckboxes/DropdownWithCheckboxes";
import PageNavigate from "@components/Navigate/PageNavigate";
import PaginationView from "@components/Pagination/PaginationView";
import Row from "@components/Wrapper/Row";
import AuthContext from "@context/AuthContext";
import { ACCESS_LEVEL_ADMIN, USERS_CREATE_PAGE_LINK, USERS_PAGE_LINK } from "@core/constants";
import useToast from "@hooks/useToast";
import { IAdminUserData } from "@interfaces/IAdmin";
import { companiesInitialState, ICompanyResponse } from "@interfaces/ICompany";
import { IRole, roleInitialState } from "@interfaces/IRole";
import TableData from "@pages/Admins/Users/UsersPage/Components/TableData";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import { roleService } from "@services/roleService";
import { queryStringBuilder } from "@utils/query";

const UsersPage: FC = () => {
  const { user } = useContext(AuthContext);
  const userRole = user.accessLevel;
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [itemViewCount, setItemViewCount] = useState(10);
  const [tableData, setTableData] = useState<IAdminUserData[]>([]);
  const [oldTableData, setOldTableData] = useState<IAdminUserData[]>([]);

  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);
  const [checkedCompanies, setCheckedCompanies] = useState<string[]>([]);

  const [roles, setRoles] = useState<IRole[]>([roleInitialState]);
  const [companies, setCompanies] = useState<ICompanyResponse[]>(companiesInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getUsers(search.toString());
        if (response.data !== null) {
          setTableData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
          setOldTableData(response.data);
        } else {
          setTableData([]);
          setOldTableData([]);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [addToast, search]);

  useEffect(() => {
    const queryString = queryStringBuilder({
      limit: itemViewCount,
      page: currentPage,
      companies: checkedCompanies,
      roles: checkedRoles
    });

    navigate(queryString);
  }, [itemViewCount, currentPage, checkedCompanies, checkedRoles, navigate]);

  useEffect(() => {
    const _companies = search.get("companies");
    if (_companies) {
      setCheckedCompanies(_companies.split(","));
    }

    const _roles = search.get("roles");
    if (_roles) {
      setCheckedRoles(_roles.split(","));
    }

    const _itemViewCount = search.get("limit");
    if (_itemViewCount && Number(_itemViewCount) > 0 && Number(_itemViewCount) <= 100) {
      setItemViewCount(Number(_itemViewCount));
    }

    const _page = search.get("page");
    if (_page) {
      if (_page && Number(_page) > 0 && Number(_page)) {
        setCurrentPage(Number(_page));
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userRole >= ACCESS_LEVEL_ADMIN) {
          const rolesResponse = await roleService.getAll();
          setRoles(rolesResponse);
          const res = await adminService.getCompany();
          if (res) {
            setCompanies(res.data);
          }
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    if (userRole >= ACCESS_LEVEL_ADMIN) {
      fetchData();
    }
  }, [userRole, addToast]);

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Пользователи</h6>
      </CardHeader>
      <AdminAccessWrapper>
        <PageNavigate
          current={USERS_PAGE_LINK}
          to={USERS_CREATE_PAGE_LINK}
          title={"Добавить"}
        />
      </AdminAccessWrapper>
      <CardBody>
        <div className="d-flex">
          <DropdownWithCheckboxes
            title={"Компания"}
            options={companies.map((val: ICompanyResponse) => {
              return val.name;
            })}
            checkedOptions={checkedCompanies}
            setCheckedOptions={setCheckedCompanies}
          />

          <DropdownWithCheckboxes
            className="ms-1"
            title={"Роль"}
            options={roles.map((val: IRole) => {
              return val.name;
            })}
            checkedOptions={checkedRoles}
            setCheckedOptions={setCheckedRoles}
          />
        </div>
        <TableData
          tableData={tableData}
          setTableData={setTableData}
          oldTableData={oldTableData}
          setOldTableData={setOldTableData}
          companies={companies}
          roles={roles}
          itemViewCount={itemViewCount}
          setItemViewCount={setItemViewCount}
          currentPage={currentPage}
        />
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default UsersPage;
