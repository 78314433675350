import { FC, useEffect, useState } from "react";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import useToast from "@hooks/useToast";
import { companyInitialValue, ICompany } from "@interfaces/ICompany";
import CompanyInfo from "@module/CompanyPage/CompanyInfo";
import { companyService } from "@services/companyService";

const CompanyPage: FC = () => {
  const { addToast } = useToast();

  const [company, setCompany] = useState<ICompany>(companyInitialValue);

  useEffect(() => {
    document.title = "О компании";

    const fetchData = async () => {
      try {
        const companyResponse = await companyService.getCompanyData();
        setCompany(companyResponse.data);
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [addToast]);

  return (
    <Card className="mt-3" style={{ minHeight: "calc(100vh - 102px - 73px)" }}>
      <CardHeader className="py-3">
        <p className="m-0 fw-bold">О компании</p>
      </CardHeader>
      <CardBody>
        <div className="row">{/* <ProfileStatistic /> */}</div>
        <div className="row">
          <div className="col">
            <CompanyInfo company={company} />
          </div>

          <div className="col">
            {/*<ProfileStatistic />*/}
          </div>
        </div>
        <div className="row">{/* <ProfilePasswordInput /> */}</div>
      </CardBody>
    </Card>
  );
};

export default CompanyPage;
