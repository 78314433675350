import { ReactNode, useLayoutEffect, useState } from "react";

import ThemeContext from "@context/ThemeContext";

type Props = {
  children: ReactNode;
};

const ThemeProvider = (props: Props) => {
  const { children } = props;
  // const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  // const defaultTheme = isDarkTheme ? "dark" : "light";

  const [theme, setTheme] = useState<string>(
    // window.localStorage.getItem("theme") || defaultTheme
    "light"
    // "dark"
  );

  useLayoutEffect(() => {
    window.localStorage.setItem("theme", theme);
    document.getElementById("root")?.setAttribute("data-bs-theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
