import { FC, ReactNode, useCallback, useState } from "react";

import Toast from "@components/Toast/Toast";
import ToastContainer from "@components/Toast/ToastContainer";
import ToastContext from "@context/ToastContext";

type ToastType = "success" | "warning" | "error" | "info";

export type AddToast = (message: string, type: ToastType) => void;

interface ToastProviderProps {
  children: ReactNode;
}

export interface IToast {
  id: number;
  message: string;
  type: ToastType;
}

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const addToast = useCallback<AddToast>((message, type) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type }]);

    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, 3000);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast
      }}
    >
      {children}
      <ToastContainer>
        {toasts.map((toast: IToast, key: number) => (
          <Toast key={key} type={toast.type} message={toast.message} />
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );

};

export default ToastProvider;