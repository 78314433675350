import qs from "qs";

export const queryStringBuilder = (params: any) => {
  return qs.stringify(params, {
    skipNulls: true,
    strictNullHandling: true,
    arrayFormat: "comma",
    encode: false,
    addQueryPrefix: true
  });
};