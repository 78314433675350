import { FC } from 'react';

import cn from 'classnames';

interface PencilSquareProps {
  className?: string;
}

const PencilSquare: FC<PencilSquareProps> = ({ className }) => {
  return (
    <i className={cn('bi bi-x-square-fill', className)} />
  );
};

export default PencilSquare;