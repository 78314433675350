import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Card from "@components/Card/Card";
import CardHeader from "@components/Card/CardHeader";
import Row from "@components/Wrapper/Row";
import ProfileInfo from "@module/ProfilePage/ProfileInfo";
import ProfilePasswordInput from "@module/ProfilePage/ProfilePasswordInput";

const ProfilePage: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Профиль";
  }, []);

  return (
    <Card className="mt-4">
      <CardHeader className="py-3">
        <p className="m-0 fw-bold">{t("profile-text")}</p>
      </CardHeader>
      <Row className="mb-3 mt-3" style={{ margin: "0.5rem" }}>
        <ProfileInfo />
        <ProfilePasswordInput />
      </Row>
    </Card>

  );
};

export default ProfilePage;
