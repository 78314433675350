import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import useTheme from '@hooks/useThemes';

import Pagination from './Pagination';

interface PaginationViewProps {
  currentPage: number;
  lastPage: number;
  itemViewCount: number;
  itemCount: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const PaginationView: FC<PaginationViewProps> = ({
                                                   currentPage,
                                                   lastPage,
                                                   itemViewCount,
                                                   itemCount,
                                                   setCurrentPage
                                                 }) => {
  const { theme } = useTheme();

  const [startCurrentItem, setStartCurrentItem] = useState<number>(0);
  const [endCurrentItem, setEndCurrentItem] = useState<number>(10);

  useEffect(() => {
    if (currentPage === 1) {
      setStartCurrentItem(1);
      if (itemViewCount > itemCount) {
        setEndCurrentItem(itemCount);
      } else {
        setEndCurrentItem(itemViewCount);
      }
    } else {
      const start = currentPage * itemViewCount - itemViewCount;
      const end = start + itemViewCount;
      if (end > itemCount) {
        setEndCurrentItem(itemCount);
      } else {
        setEndCurrentItem(end);
      }
      setStartCurrentItem(start);
    }
  }, [currentPage, itemViewCount, itemCount]);

  return (
    <div>
      <p className={theme === 'light' ? 'text-black' : 'text-white'}>
        Показаны с {startCurrentItem} по {endCurrentItem}{' '}
        из {itemCount}
      </p>
      <Pagination
        currentPage={currentPage}
        pageCount={lastPage}
        totalCount={itemCount}
        pageSize={itemViewCount}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default PaginationView;