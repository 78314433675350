import { FC } from 'react';

import cn from 'classnames';

interface PeopleIconProps {
  className?: string;
}

const PeopleIcon: FC<PeopleIconProps> = ({ className }) => {
  return (
    <i className={cn('fs-4 bi bi-people', className)} />
  );
};

export default PeopleIcon;