import { ChangeEvent, Dispatch, FC } from 'react';

interface ReleaseDateInputProps {
  releaseDate: string;
  setReleaseDate: Dispatch<string>;
}

const ReleaseDateInput: FC<ReleaseDateInputProps> = ({ releaseDate, setReleaseDate }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReleaseDate(e.target.value);
  };

  return (
    <div className="col-12">
      <label>Дата релиза</label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-calendar-date"></i>
        </div>
        <input
          type="date"
          className="form-control"
          name="releaseDate"
          onChange={handleChange}
          value={releaseDate}
        />
      </div>
    </div>
  );
};

export default ReleaseDateInput;