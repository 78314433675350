import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { IContract } from "@interfaces/IContract";

interface SelectContractProps {
  selectedContract: IContract;
  setSelectedContract: Dispatch<SetStateAction<IContract>>;
  contracts: IContract[];
  setContractNumber: Dispatch<SetStateAction<string>>;
  setContractID: Dispatch<SetStateAction<number>>;
  contractNumber: string;

}

const SelectContract: FC<SelectContractProps> = ({
                                                   selectedContract,
                                                   setSelectedContract,
                                                   contracts,
                                                   setContractNumber,
                                                   setContractID,
                                                   contractNumber
                                                 }) => {
  const { t } = useTranslation();

  const handleContractChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const contractNumber = e.target.value;
    setContractNumber(contractNumber);

    const contractID = findContractIDByContractNumber(contractNumber);
    setContractID(contractID);
    const data = getContractDataByContractNumber(contractNumber);

    if (data) {
      setSelectedContract(data);
    }
  };

  const findContractIDByContractNumber = (contractNumber: string): number => {
    const contract = contracts.find((el: IContract) => el.contractNumber === contractNumber);

    return contract ? contract.contractID : 0;
  };

  const getContractDataByContractNumber = useCallback((contractNumber: string) => {
    return contracts.find((item: IContract) => {
      return item.contractNumber === contractNumber;
    });
  }, [contracts]);

  useEffect(() => {
    const currentContractData = getContractDataByContractNumber(contractNumber);
    if (currentContractData) {
      setSelectedContract(currentContractData);
    }
  }, [contractNumber, getContractDataByContractNumber, setSelectedContract]);

  return (
    <label className="form-label" htmlFor="contractSelect">
      {t("select-contract-text")}: &nbsp;
      <select
        id="contractSelect"
        className="d-inline-block form-select form-select-sm"
        onChange={handleContractChange}
        value={selectedContract.contractNumber}
      >
        {contracts && contracts.map((item: IContract, key: number) => (
          <option key={key} value={item.contractNumber}>
            {item.contractNumber}
          </option>
        ))}
      </select>
      &nbsp;
    </label>
  );
};

export default SelectContract;