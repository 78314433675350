import { createContext } from "react";

import { AxiosError } from "axios";

import { IUserAuth, SignInCredentials } from "@interfaces/IUser";

export type AuthContextData = {
  user: IUserAuth
  isAuth: boolean
  loadingUserData: boolean
  signIn: (credentials: SignInCredentials) => Promise<void | AxiosError>
  signOut: () => void
}

const AuthContext = createContext({} as AuthContextData);

export default AuthContext;