import { ChangeEvent, FC, useId, useState } from "react";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import Col from "@components/Wrapper/Col/Col";
import Row from "@components/Wrapper/Row";
import useToast from "@hooks/useToast";
import { userService } from "@services/userService";

const ProfilePasswordInput: FC = () => {
  const id = useId();
  const { addToast } = useToast();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      addToast("Пароли не совпадают", "warning");
    } else {
      try {
        const response = await userService.changePassword(oldPassword, password);
        if (response) {
          addToast(response.data.message, "success");
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };

  return (
    <Card className="mt-3">
      <CardHeader className="py-3">
        <p className="m-0">Смена пароля</p>
      </CardHeader>
      <CardBody>
        <div className="mb-3">
          <label className="form-label" htmlFor={`old_password-${id}`}>
            <strong>Старый пароль</strong>
          </label>
          <input
            className="form-control"
            type="password"
            id={`old_password-${id}`}
            placeholder="Старый пароль"
            name="old_password"
            value={oldPassword}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setOldPassword(e.target.value)
            }
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor={`password-${id}`}>
            <strong>Новый пароль</strong>
          </label>
          <input
            className="form-control"
            type="password"
            id={`password-${id}`}
            placeholder="Новый пароль"
            name="password"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </div>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor={`confirmPassword-${id}`}>
                <strong>Повторите пароль</strong>
              </label>
              <input
                className="form-control"
                type="password"
                id={`confirmPassword-${id}`}
                placeholder="Повторите пароль"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </div>
          </Col>

        </Row>

        <div className="mb-3">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
          >
            Сохранить&nbsp;пароль
          </button>
        </div>
      </CardBody>

    </Card>

  );
};

export default ProfilePasswordInput;
