import { Dispatch, FC, SetStateAction } from "react";

interface GuaranteeLetterInputProps {
  guaranteeLetterEnabled: boolean;
  setGuaranteeLetterEnabled: Dispatch<SetStateAction<boolean>>;
}

const GuaranteeLetterInput: FC<GuaranteeLetterInputProps> = ({ guaranteeLetterEnabled, setGuaranteeLetterEnabled }) => {
  return (
    <div className="col-12 d-flex">
      <label htmlFor={`guaranteeLetterEnabled`} className="me-2">
        Добавить гарантийное письмо:
      </label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={`guaranteeLetterEnabled`}
          name="guaranteeLetterEnabled"
          checked={guaranteeLetterEnabled}
          onChange={(e) => {
            setGuaranteeLetterEnabled(e.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default GuaranteeLetterInput;