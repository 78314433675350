import { FC, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PageNavigate from "@components/Navigate/PageNavigate";
import Col from "@components/Wrapper/Col/Col";
import Row from "@components/Wrapper/Row";
import { getUserInfoPageLinkByID, USERS_PAGE_LINK } from "@core/constants";
import useToast from "@hooks/useToast";
import { IUser, userNewInitialState } from "@interfaces/IUser";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import { formatDateTime } from "@utils/date";

const UserInfoPage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();

  const [userInfo, setUserInfo] =
    useState<IUser>(userNewInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getUserByID(Number(id));

        if (response) {
          setUserInfo(response);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, [id, addToast]);

  const handleClick = async (e: any) => {
    e.preventDefault();

    try {
      const response = await adminService.repeatRegistrationEmail(userInfo.email);
      if (response.data) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Данные пользователя</h6>
      </CardHeader>
      <PageNavigate
        current={getUserInfoPageLinkByID(Number(id))}
        to={USERS_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="username">
                <strong>Имя</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="username"
                placeholder="Имя"
                name="username"
                value={userInfo.name}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="surname">
                <strong>Фамилия</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="surname"
                placeholder="Фамилия"
                name="surname"
                value={userInfo.surname}
                disabled
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                <strong>Email</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="email"
                placeholder="email"
                name="email"
                value={userInfo.email}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="company">
                <strong>Компания</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="company"
                placeholder="Компания"
                name="company_name"
                value={userInfo.company_name}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="role_name">
                <strong>Тип аккаунта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="role_name"
                placeholder="Роль"
                name="role_name"
                value={userInfo?.role.name}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="is_banned">
                <strong>Статус аккаунта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="is_banned"
                placeholder="Роль"
                name="is_banned"
                value={userInfo.is_banned ? "Заблокирован" : "Активен"}
                disabled
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="last_visited_at">
                <strong>Дата последнего посещения</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="last_visited_at"
                placeholder="Роль"
                name="last_visited_at"
                value={userInfo.last_visited_at ? formatDateTime(userInfo.last_visited_at) : "Нет посещений"}
                disabled
              />
            </div>
          </Col>
          <Col>
            <div className="mb-3">
              <label className="form-label" htmlFor="created_at">
                <strong>Дата создания аккаунта</strong>
              </label>
              <input
                className="form-control"
                type="text"
                id="created_at"
                placeholder="Роль"
                name="created_at"
                value={formatDateTime(userInfo.created_at)}
                disabled
              />
            </div>
          </Col>
        </Row>
        {!userInfo.last_visited_at && (
          <AdminAccessWrapper>
            <Row className="mt-3">
              <Col>
                <button className="btn btn-primary" onClick={handleClick}>
                  Отправить письмо с инструкцией по регистрации
                </button>
              </Col>
            </Row>
          </AdminAccessWrapper>
        )}
      </CardBody>
    </Card>
  );
};

export default UserInfoPage;