import { ChangeEvent, Dispatch, FC, SetStateAction, useId } from "react";

import { IPlugin } from "@interfaces/ILicenses";

interface PluginsComponentProps {
  plugins: IPlugin;
  setPlugins: Dispatch<SetStateAction<IPlugin>>;
}

const PluginsInput: FC<PluginsComponentProps> = ({ plugins, setPlugins }) => {
  const id = useId();

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const state = e.target.checked;

    setPlugins({
      smtcp: state,
      opcUa: state,
      modbus: state,
      redundancy: state,
      elna: state,
      onlineTransfer: state,
      archiver: state
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setPlugins({ ...plugins, [name]: checked });
  };

  return (
    <>
      <div className="col-12 d-flex">
        <label htmlFor={`selectAllPlugins-${id}`} className="me-2">
          Включить все плагины:
        </label>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`selectAllPlugins-${id}`}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
      <div className="col-12">
        <label>Плагины</label>
        {Object.entries(plugins).map(([item, isChecked], key) => (
          <div key={key} className="input-group">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id={`plugin-${item}-${id}`}
                name={item}
                checked={isChecked}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor={`plugin-${item}-${id}`}>
                {item}
              </label>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PluginsInput;