import { FC, MouseEvent, useContext, useEffect, useId, useState } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@components/Button/Button";
import Footer from "@components/Footer/Footer";
import LogoHighIcon from "@components/Icons/LogoHighIcon";
import AuthContext from "@context/AuthContext";
import { MAIN_PAGE_LINK, SIGN_IN_PAGE_LINK } from "@core/constants";
import useTheme from "@hooks/useThemes";
import useToast from "@hooks/useToast";
import { authService } from "@services/authService";

import styles from "./ResetPassword.module.scss";

const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const id = useId();
  const { theme } = useTheme();
  const { addToast } = useToast();

  const navigate = useNavigate();

  const { isAuth } = useContext<any>(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorEqualPassword, setErrorEqualPassword] = useState(false);

  const url: URL = new URL(window.location.href);
  const params: URLSearchParams = url.searchParams;
  const resetToken: string | null = params.get("refreshToken");

  if (isAuth) {
    navigate(MAIN_PAGE_LINK);
  }

  if (resetToken === null) {
    navigate(SIGN_IN_PAGE_LINK);
  }

  useEffect(() => {
    document.title = "Восстановление пароля";
  }, []);

  const handleEvent = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorEqualPassword(true);
    } else {
      if (resetToken === null) {
        navigate(SIGN_IN_PAGE_LINK);
      } else {
        setErrorEqualPassword(false);
        try {
          const response = await authService.resetPassword(
            resetToken,
            password
          );
          if (response) {
            navigate(SIGN_IN_PAGE_LINK);
          }
        } catch (e: any) {
          if (e.response.status >= 400 && e.response.status < 500) {
            addToast(e.response.data.message, "warning");
          } else if (e.response.status >= 500) {
            addToast(e.response.data.message, "error");
          }
        }
      }
    }
  };
  return (
    <>
      <section
        className={cn("vh-100 d-flex flex-column", styles.root, {
          [styles.dark]: theme === "dark",
          [styles.light]: theme === "light"
        })}
      >
        <div
          className="container-fluid h-custom d-flex justify-content-center"
          style={{ flex: 1 }}
        >
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <LogoHighIcon />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form>
                <h3>Восстановление пароля</h3>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor={`password-${id}`}>
                    {t("label-password")}
                  </label>
                  <input
                    type="password"
                    id={`password-${id}`}
                    className={cn(
                      "form-control form-control-lg",
                      styles["input-field"]
                    )}
                    placeholder={t("enter-new-password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errorEqualPassword && (
                    <div className="text-danger">
                      {t("password-mismatch-text")}
                    </div>
                  )}
                </div>
                <div className="form-outline mb-4">
                  <label
                    className="form-label"
                    htmlFor={`confirmPassword-${id}`}
                  >
                    {t("repeat-password-text")}
                  </label>
                  <input
                    type="password"
                    id={`confirmPassword-${id}`}
                    className={cn(
                      "form-control form-control-lg",
                      styles["input-field"]
                    )}
                    placeholder={t("repeat-password-text")}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex text-center text-lg-start mt-4 pt-2">
                  <Button
                    className="btn btn-primary btn-lg"
                    onClick={handleEvent}
                  >
                    {t("submit-button-text")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default ResetPassword;
