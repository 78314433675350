import { FC, useEffect, useState } from "react";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import ContractNumberInput from "@components/Contract/ContractNumberInput";
import DateOfConclusionContractInput from "@components/Contract/DateOfConclusionContractInput";
import DescriptionTextInput from "@components/Contract/DescriptionTextInput";
import ExpireDateInput from "@components/Contract/ExpireDateInput";
import HwFixComponents from "@components/Contract/HwFixComponents";
import LicenseCountInput from "@components/Contract/LicenseCountInput";
import LicVersionInput from "@components/Contract/LicVersionInput";
import NeverExpireInput from "@components/Contract/NeverExpireInput";
import PluginsInput from "@components/Contract/PluginsInput";
import PageNavigate from "@components/Navigate/PageNavigate";
import { CONTRACT_ORDER_CREATE_PAGE_LINK, CONTRACT_PAGE_LINK } from "@core/constants";
import useToast from "@hooks/useToast";
import { companyAndContractInitialState, ICompaniesAndContract } from "@interfaces/ICompany";
import { IContractOrderInput } from "@interfaces/IContract";
import { pluginInitialStateInput } from "@interfaces/ILicenses";
import { adminService } from "@services/adminService";

const CreateContractOrders: FC = () => {
  const { addToast } = useToast();

  const [companiesAndContract, setCompaniesAndContract] =
    useState<ICompaniesAndContract>([companyAndContractInitialState]);

  const [isCompanySelected, setIsCompanySelected] = useState<boolean>(false);
  const [isContractSelected, setIsContractSelected] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getCompaniesAndContract();
        if (response) {
          setCompaniesAndContract(response);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, [addToast]);

  const [companyID, setCompanyID] = useState<number>(0);
  const [contractID, setContractID] = useState<number>(0);
  const [isCheckedSubmitButton, setIsCheckedSubmitButton] = useState<boolean>(true);

  const [contractNumber, setContractNumber] = useState("");
  const [licCount, setLicCount] = useState(0);
  const [licVersion, setLicVersion] = useState(2);
  const [dateOfConclusion, setDateOfConclusion] = useState(String(new Date()));
  const [expirationDate, setExpirationDate] = useState(String(new Date()));
  const [hwFix, setHwFix] = useState(false);
  const [neverExpire, setNeverExpire] = useState(false);
  const [plugins, setPlugins] = useState(pluginInitialStateInput);
  const [description, setDescription] = useState<string>("");

  const contracts =
    companiesAndContract.find((company) => company.companyID === companyID)?.contracts;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData: IContractOrderInput = {
      company_id: companyID,
      contract_id: contractID,
      order_number: contractNumber,
      is_order_number_exist: true,
      date_of_conclusion: new Date(dateOfConclusion).toISOString(),
      lic_count: licCount,
      lic_version: licVersion,
      hw_fix: hwFix,
      never_expire: neverExpire,
      description: description,
      plugins: {
        smtcp: plugins.smtcp,
        opc_ua: plugins.opcUa,
        modbus: plugins.modbus,
        redundancy: plugins.redundancy,
        elna: plugins.elna,
        online_transfer: plugins.onlineTransfer,
        archiver: plugins.archiver
      }
    };

    if (!neverExpire) {
      Object.assign(formData, { expiration_date: new Date(expirationDate).toISOString() });
    }

    try {
      const response: any = await adminService.createContractOrder(formData);
      if (response) {
        addToast(response.data.message, "success");
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    if (
      companyID !== -1 &&
      contractNumber !== "" &&
      licCount !== 0 &&
      licVersion !== 0 &&
      dateOfConclusion !== "" &&
      (expirationDate !== "" || neverExpire)
    ) {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [
    companyID,
    contractNumber,
    licCount,
    licVersion,
    dateOfConclusion,
    expirationDate,
    neverExpire
  ]);

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Добавить заявку к контракту</h6>
      </CardHeader>

      <PageNavigate
        current={CONTRACT_ORDER_CREATE_PAGE_LINK}
        to={CONTRACT_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <div className="form-left h-100 py-5 px-5">
          <form className="row g-4">

            <div className="col-12">
              <label>Компания </label>
              <div className="input-group">
                <div className="input-group-text">
                  <i className="bi bi-person-fill"></i>
                </div>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="company_id"
                  onChange={(e) => {
                    setCompanyID(Number(e.target.value));
                    setContractID(0);
                    setIsCompanySelected(true);
                  }}
                  defaultValue={-6}
                >
                  <option value={-6} disabled>
                    Выберите компанию
                  </option>
                  {companiesAndContract.map((company) => (
                    <option key={company.companyID} value={company.companyID}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {companyID > -1 && isCompanySelected && (
              <div className="col-12 mt-3">
                <label>Контракт </label>
                <div className="input-group">
                  <div className="input-group-text">
                    <i className="bi bi-person-fill"></i>
                  </div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="contract_id"
                    defaultValue={-6}
                    onChange={(e) => {
                      setContractID(Number(e.target.value));
                      setIsContractSelected(true);
                    }}
                  >
                    <option value={-6} disabled>
                      Выберите номер контракта
                    </option>
                    {contracts && contracts.map((contract) => (
                      <option key={contract.contractID} value={contract.contractID}>
                        {contract.contractNumber}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {companyID !== -1 && isCompanySelected && isContractSelected && (
              <>
                <ContractNumberInput
                  contractNumber={contractNumber}
                  setContractNumber={setContractNumber}
                  isContractOrder={true}
                />

                <DateOfConclusionContractInput
                  dateOfConclusion={dateOfConclusion}
                  setDateOfConclusion={setDateOfConclusion}
                  isContractOrder={true}
                />

                <>
                  <ExpireDateInput
                    expirationDate={expirationDate}
                    setExpirationDate={setExpirationDate}
                    neverExpire={neverExpire}
                  />

                  <LicenseCountInput
                    licCount={licCount}
                    setLicCount={setLicCount}
                  />

                  <LicVersionInput
                    licVersion={licVersion}
                    setLicVersion={setLicVersion}
                  />

                  <HwFixComponents
                    hwFix={hwFix}
                    setHwFix={setHwFix}
                  />

                  <NeverExpireInput
                    neveExpire={neverExpire}
                    setNeveExpire={setNeverExpire}
                  />

                  <PluginsInput
                    plugins={plugins}
                    setPlugins={setPlugins}
                  />

                  <DescriptionTextInput
                    description={description}
                    setDescription={setDescription}
                    isContractOrder={true}
                  />
                </>

                <div className="d-flex col-12">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary px-4 float-end mt-4"
                    disabled={isCheckedSubmitButton}
                    style={{ marginLeft: "auto" }}>
                    Создать
                  </button>
                </div>
              </>
            )}

          </form>
        </div>
      </CardBody>
    </Card>
  );
};

export default CreateContractOrders;