import { Dispatch, FC, Fragment, SetStateAction, useState } from "react";

import CardBody from "@components/Card/CardBody";
import PaginationView from "@components/Pagination/PaginationView";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import useToast from "@hooks/useToast";
import { ILicense } from "@interfaces/ILicenses";
import { userService } from "@services/userService";

import TableHead from "./TableHead";
import CcbFileRow from "./TableRow/CcbFileRow";
import CcbOldFileNameRow from "./TableRow/CcbOldFileNameRow";
import DescriptionRow from "./TableRow/DescriptionRow";
import ExpDateRow from "./TableRow/ExpDateRow";
import IssueDate from "./TableRow/IssueDate";
import LicenseNumberRow from "./TableRow/LicenseNumberRow";
import LicFileRow from "./TableRow/LicFileRow";

interface LicensesTableProps {
  contractID: number;
  data: ILicense[];
  setData: Dispatch<ILicense[]>;
  itemViewCount: number;
  setItemViewCount: Dispatch<SetStateAction<number>>;
  isLicExist: boolean;
  currentPage: number;
  lastPage: number;
  itemCount: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const LicensesTable: FC<LicensesTableProps> = ({
                                                 contractID,
                                                 data,
                                                 setData,
                                                 itemViewCount,
                                                 setItemViewCount,
                                                 isLicExist,
                                                 currentPage,
                                                 lastPage,
                                                 itemCount,
                                                 setCurrentPage
                                               }) => {
  const { addToast } = useToast();

  const [searchValue, setSearchValue] = useState<string>("");

  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  const filteredTableData = data && data.filter((val) => {
    return (
      val.licenseNumber.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      val.ccbFileName?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      val.description?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );
  });

  const handleDownloadClick = async (downloadUrl: string) => {
    try {
      const response = await userService.handleDownloadClick(downloadUrl);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "file";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      } else {
        const urlSegments = downloadUrl.split("/");
        fileName = urlSegments[urlSegments.length - 1];
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e: any) {
      const error = JSON.parse(await e.response.data.text());
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(error.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(error.message, "error");
      }
    }
  };

  return (
    <Fragment>
      {isLicExist ? (
        <CardBody>
          <Row>
            <TableViewItem
              itemViewCount={itemViewCount}
              setItemViewCount={setItemViewCount}
            />
            <TableSearchItem
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Row>
          <Row>
            <div className="table-responsive mt-2">
              <table className="table table-striped table-bordered mt-2">
                <TableHead />
                <tbody>
                {filteredTableData.length > 0 && filteredTableData.map((item: ILicense, key: number) => (
                  <tr key={key}>
                    <td style={{ width: "20px", verticalAlign: "middle", textAlign: "center" }}>{getRowNumber(key)}</td>
                    <LicenseNumberRow licenseNumber={item.licenseNumber} />
                    <ExpDateRow expDate={item.expirationDate} />
                    <CcbOldFileNameRow ccbOldFileName={item.ccbOldFileName} />
                    <LicFileRow
                      licFileName={item.licFileName}
                      handleDownloadClick={handleDownloadClick}
                    />
                    <CcbFileRow
                      ccbFileName={item.ccbFileName}
                      handleDownloadClick={handleDownloadClick}
                      contractID={contractID}
                      data={data}
                      setData={setData}
                      licenseNumber={item.licenseNumber}
                    />
                    <DescriptionRow item={item} />
                    <IssueDate issueDate={item.issueDate} />
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <PaginationView
              currentPage={currentPage}
              lastPage={lastPage}
              itemViewCount={itemViewCount}
              itemCount={itemCount}
              setCurrentPage={setCurrentPage}
            />
          </Row>
        </CardBody>
      ) : (
        <div>Лицензии к контракту не найдены!</div>
      )}
    </Fragment>
  );
};
export default LicensesTable;
