import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';

import { getAccessToken, removeSession } from '@utils/tokens';
import { generateRequestID } from '@utils/utils';

import { api, apiDefault } from './axios';
import { HTTP_MESSAGE_ACCOUNT_BLOCKED, SESSION_TYPE, SIGN_IN_PAGE_LINK, STORAGE_ACCESS_TOKEN } from './constants';

type FailedRequestQueue = {
  onSuccess: (token: string) => void
  onFailure: (error: AxiosError) => void
}

let isRefreshing = false;
let failedRequestQueue: FailedRequestQueue[] = [];

type SetAuthHeaderParams = {
  request: AxiosDefaults | AxiosRequestConfig
  token: string
}

export const setAuthHeader = (params: SetAuthHeaderParams) => {
  const { request, token } = params;
  (request.headers as Record<string, unknown>)['Authorization'] = `Bearer ${token}`;
  (request.headers as Record<string, unknown>)['X-Request-ID'] = generateRequestID();
};

export const handleRefreshToken = async () => {
  isRefreshing = true;

  try {
    const response = await apiDefault.get('/auth/refresh-token');
    const accessToken = response.data.AccessToken;
    if (localStorage.getItem(SESSION_TYPE) === 'local') {
      localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);
    } else {
      localStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken);
    }

    failedRequestQueue.forEach((request) => request.onSuccess(accessToken));
    failedRequestQueue = [];
  } catch (error) {
    // @ts-ignore
    failedRequestQueue.forEach((request) => request.onFailure(error));
    removeSession();
    window.location.href = SIGN_IN_PAGE_LINK;
  } finally {
    isRefreshing = false;
  }
};

const onRequest = (config: AxiosRequestConfig) => {
  const token = getAccessToken();

  if (token) {
    setAuthHeader({ request: config, token });
  }

  return config as InternalAxiosRequestConfig;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: any): Promise<AxiosError | AxiosResponse> => {
    if (error?.response?.status === 401) {
      if (error.response?.statusText === 'Unauthorized') {
        const originalConfig = error.config;
        originalConfig._isRetry = true;

        if (!isRefreshing) {
          await handleRefreshToken();
        }

        return new Promise((resolve, reject) => {
          const token = getAccessToken();

          setAuthHeader(({ request: originalConfig, token }));
          resolve(api(originalConfig));

        });
      } else {
        removeSession();
        window.location.href = SIGN_IN_PAGE_LINK;
      }
    } else if (error?.response?.status === 403 &&
      error.response?.statusText === 'Forbidden' &&
      error.response?.data.message === HTTP_MESSAGE_ACCOUNT_BLOCKED) {
      removeSession();
      window.location.href = SIGN_IN_PAGE_LINK;
    }

    return Promise.reject(error);
  }
;

export const setupInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};