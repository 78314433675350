import { FC } from "react";

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import FallingStarsProvider from "@providers/FallingStarsProvider";

import AuthProvider from "./providers/AuthProvider";
import ThemeProvider from "./providers/ThemeProvider";
import ToastProvider from "./providers/ToastProvider";
import Router from "./routes/Router";

const App: FC = () => {
  return (
    <ThemeProvider>
      <FallingStarsProvider>
        <ToastProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ToastProvider>
      </FallingStarsProvider>
    </ThemeProvider>
  );
};

export default App;
