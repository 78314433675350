import { Route, Routes } from "react-router-dom";

import AdminLayout from "@components/Layout/AdminLayout/AdminLayout";
import MainLayout from "@components/Layout/UserLayout/MainLayout";
import {
  ACCESS_LEVEL_ADMIN,
  ACCESS_LEVEL_CHIEF_ADMIN,
  ACCESS_LEVEL_MODERATOR,
  AUDIT_PAGE_LINK,
  COMPANIES_CREATE_PAGE_LINK,
  COMPANIES_PAGE_LINK,
  COMPANY_CONTRACTS_PAGE_LINK,
  COMPANY_PAGE_LINK,
  CONTRACT_CREATE_PAGE_LINK,
  CONTRACT_LICENSE_INFO_BY_NUMBER_PAGE_LINK,
  CONTRACT_LICENSES_BY_CONTRACT_ID_PAGE_LINK,
  CONTRACT_ORDER_CREATE_PAGE_LINK,
  CONTRACT_ORDERS_BY_CONTRACT_ID_PAGE_LINK,
  CONTRACT_PAGE_LINK,
  DOWNLOAD_PAGE_LINK,
  FORGOT_PASSWORD_PAGE_LINK,
  GENERATE_LICENSE_PAGE_LINK,
  LICENSE_PAGE_LINK,
  MAIN_PAGE_LINK,
  PROFILE_PAGE_LINK,
  REGISTER_PAGE_LINK,
  RESET_PASSWORD_PAGE_LINK,
  SIGN_IN_PAGE_LINK,
  SOFTWARE_CREATE_PAGE_LINK,
  SOFTWARE_INFO_PAGE_LINK,
  SOFTWARE_PAGE_LINK,
  USERS_BY_ID_PAGE_LINK,
  USERS_CREATE_PAGE_LINK,
  USERS_PAGE_LINK
} from "@core/constants";
import useSession from "@hooks/useSession";
import AuditPage from "@pages/Admins/Audit/AuditPage";
import AdminPanelCompanyPage from "@pages/Admins/Company/CompaniesPage";
import CompanyContractsPage from "@pages/Admins/Company/CompanyContractsPage";
import CreateCompanyPage from "@pages/Admins/Company/CreateCompanyPage";
import ContractOrdersPage from "@pages/Admins/Contract/ContractOrdersPage";
import ContractPage from "@pages/Admins/Contract/ContractPage/ContractPage";
import CreateContractOrders from "@pages/Admins/Contract/CreateContractOrders";
import CreateContractPage from "@pages/Admins/Contract/CreateContractPage";
import GenerateLicensePage from "@pages/Admins/GenerateLicense/GenerateLicensePage";
import LicenseInfoPage from "@pages/Admins/Licenses/LicenseInfoPage";
import LicensePage from "@pages/Admins/Licenses/LicensePage";
import AdminPanelMainPage from "@pages/Admins/MainPage/AdminPanelMainPage";
import SoftwareInfoPage from "@pages/Admins/SoftwarePage/SoftwareInfoPage";
import SoftwarePage from "@pages/Admins/SoftwarePage/SoftwarePage";
import SoftwareUploadPage from "@pages/Admins/SoftwarePage/SoftwareUploadPage";
import CreateUserPage from "@pages/Admins/Users/CreateUserPage";
import UserInfoPage from "@pages/Admins/Users/UserInfoPage";
import UsersPage from "@pages/Admins/Users/UsersPage/UsersPage";
import ForgotPassword from "@pages/Common/Auth/ForgotPassword/ForgotPassword";
import LoginPage from "@pages/Common/Auth/LoginPage/LoginPage";
import RegisterPage from "@pages/Common/Auth/RegisterPage/RegisterPage";
import ResetPassword from "@pages/Common/Auth/ResetPassword/ResetPassword";
import NotFoundPage from "@pages/Common/NotFound/NotFoundPage";
import ProfilePage from "@pages/Common/ProfilePage/ProfilePage";
import CompanyPage from "@pages/Users/CompanyPage";
import DownloadPage from "@pages/Users/Download/DownloadPage";
import MainPage from "@pages/Users/MainPage/MainPage";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Router = () => {
  const { isAuth, user, signOut } = useSession();
  let hasPermission = false;

  if (isAuth && user.accessLevel === -1) {
    signOut();
  }

  if (isAuth) {
    let adminRole = Number(user.accessLevel);
    if (isNaN(adminRole) && !isAuth) {
      signOut();
    }

    if (adminRole > 0) {
      hasPermission = adminRole > 1;
    }
  } else {
    <Routes>
      <Route
        path={SIGN_IN_PAGE_LINK}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
    </Routes>;
  }
  return (
    <Routes>
      {!hasPermission ?
        (
          <Route path="/" element={<MainLayout />}>
            <Route
              path={MAIN_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <MainPage />
                </PrivateRoute>
              }
            />

            <Route
              path={COMPANY_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <CompanyPage />
                </PrivateRoute>
              }
            />

            <Route
              path={LICENSE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <MainPage />
                </PrivateRoute>
              }
            />

            <Route
              path={PROFILE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <ProfilePage />
                </PrivateRoute>
              }
            />

            <Route
              path={DOWNLOAD_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <DownloadPage />
                </PrivateRoute>
              }
            />

          </Route>

        ) : (
          <Route path="/" element={<AdminLayout />}>
            <Route
              path={MAIN_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <AdminPanelMainPage />
                </PrivateRoute>
              }
            />

            <Route
              path={USERS_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <UsersPage />
                </PrivateRoute>
              }
            />

            <Route
              path={USERS_CREATE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <CreateUserPage />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <ContractPage />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_CREATE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <CreateContractPage />
                </PrivateRoute>
              }
            />

            <Route
              path={COMPANIES_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <AdminPanelCompanyPage />
                </PrivateRoute>
              }
            />

            <Route
              path={COMPANIES_CREATE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <CreateCompanyPage />
                </PrivateRoute>
              }
            />

            <Route
              path={PROFILE_PAGE_LINK}
              element={
                <PrivateRoute redirectTo={SIGN_IN_PAGE_LINK}>
                  <ProfilePage />
                </PrivateRoute>
              }
            />
            <Route
              path={GENERATE_LICENSE_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  permissions={[ACCESS_LEVEL_CHIEF_ADMIN, ACCESS_LEVEL_ADMIN]}
                >
                  <GenerateLicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path={SOFTWARE_CREATE_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  permissions={[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_CHIEF_ADMIN]}
                >
                  <SoftwareUploadPage />
                </PrivateRoute>
              }
            />

            <Route
              path={SOFTWARE_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  permissions={[ACCESS_LEVEL_MODERATOR, ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_CHIEF_ADMIN]}
                >
                  <SoftwarePage />
                </PrivateRoute>
              }
            />

            <Route
              path={SOFTWARE_INFO_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  // permissions={[ACCESS_LEVEL_ADMIN]}
                >
                  <SoftwareInfoPage />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_ORDER_CREATE_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  // permissions={[ACCESS_LEVEL_ADMIN]}
                >
                  <CreateContractOrders />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_LICENSES_BY_CONTRACT_ID_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  // permissions={[ACCESS_LEVEL_ADMIN]}
                >
                  <LicensePage />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_LICENSE_INFO_BY_NUMBER_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  // permissions={[ACCESS_LEVEL_ADMIN]}
                >
                  <LicenseInfoPage />
                </PrivateRoute>
              }
            />

            <Route
              path={USERS_BY_ID_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                  // permissions={[ACCESS_LEVEL_ADMIN]}
                >
                  <UserInfoPage />
                </PrivateRoute>
              }
            />

            <Route
              path={COMPANY_CONTRACTS_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                >
                  <CompanyContractsPage />
                </PrivateRoute>
              }
            />

            <Route
              path={AUDIT_PAGE_LINK}
              element={
                <PrivateRoute
                  permissions={[ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_CHIEF_ADMIN]}
                  redirectTo={SIGN_IN_PAGE_LINK}
                >
                  <AuditPage />
                </PrivateRoute>
              }
            />

            <Route
              path={CONTRACT_ORDERS_BY_CONTRACT_ID_PAGE_LINK}
              element={
                <PrivateRoute
                  redirectTo={SIGN_IN_PAGE_LINK}
                >
                  <ContractOrdersPage />
                </PrivateRoute>
              }
            />

          </Route>
        )}

      <Route
        path={SIGN_IN_PAGE_LINK}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />

      <Route
        path={FORGOT_PASSWORD_PAGE_LINK}
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />

      <Route
        path={RESET_PASSWORD_PAGE_LINK}
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route
        path={REGISTER_PAGE_LINK}
        element={
          <PublicRoute>
            <RegisterPage />
          </PublicRoute>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>

  );
};

export default Router;
