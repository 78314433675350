import { ChangeEvent, CSSProperties, Dispatch, FC, SetStateAction, useEffect, useId, useRef, useState } from "react";

import cn from "classnames";

interface DropdownWithCheckboxesProps {
  title: string;
  options: string[];
  checkedOptions: string[];
  setCheckedOptions: Dispatch<SetStateAction<string[]>>;
  className?: string;
  style?: CSSProperties;
}

const DropdownWithCheckboxes: FC<DropdownWithCheckboxesProps> = ({
                                                                   title,
                                                                   options,
                                                                   checkedOptions,
                                                                   setCheckedOptions,
                                                                   className,
                                                                   style
                                                                 }) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((val: string) => {
    return val.toLocaleLowerCase().includes(search.toLocaleLowerCase());
  });

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleClickOutside = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedOptions([...checkedOptions, value]);
    } else {
      setCheckedOptions(checkedOptions.filter((obj: string) => obj !== value));
    }
  };

  return (
    <div className={cn("dropdown", className)} ref={dropdownRef} style={{ ...style }}>
      <button
        className="btn btn-primary btn-sm dropdown-toggle"
        type="button"
        id={`${id}-dropdownMenuButton`}
        onClick={toggleDropdown}
      >
        {checkedOptions.length > 0 ? `${title} (${checkedOptions.length})` : title}
      </button>
      {isOpen && (
        <ul
          className={cn("dropdown-menu mt-1", isOpen ? "show" : "")}
          style={{
            maxHeight: "350px",
            overflowY: "auto",
            overflowX: "hidden",
            zIndex: 1050,
          }}
        >
          <li className="dropdown-item" style={{ backgroundColor: "transparent" }}>
            <input
              className="form-control form-control-sm" type="text"
              placeholder="Поиск..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </li>
          {options.length > 0 && filteredOptions.length > 0 ? filteredOptions.map((option: string, key: number) => {
            const optionID = `${option}-${id}`;
            return (
              <li className="dropdown-item" key={key}>
                <div className="form-check">
                  <label
                    className="form-check-label w-100"
                    htmlFor={optionID}
                    style={{ cursor: "pointer" }}
                  >
                    {option}
                  </label>
                  <input
                    className="form-check-input"

                    type="checkbox"
                    value={option}
                    checked={checkedOptions.includes(option)}
                    id={optionID}
                    onChange={handleChange}
                  />
                </div>
              </li>
            );
          }) : (
            <li className="dropdown-item disabled">
              <div className="form-check m-0 p-0">{title} не найдена</div>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default DropdownWithCheckboxes;