import { FC } from "react";

import cn from "classnames";
import { Outlet } from "react-router-dom";

import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";
import SnowmanIcon from "@components/Icons/SnowmanIcon";
import useTheme from "@hooks/useThemes";
import { isHolidaySeason } from "@utils/holidays";

import styles from "./Layout.module.scss";

const MainLayout: FC = () => {
  const { theme } = useTheme();

  return (
    <div className={styles.container}>
      {isHolidaySeason() && <SnowmanIcon />}
      <div className={styles.row}>
        <div className={styles.col}>
          <Header />
          {/*<div className="d-flex flex-column justify-content-between">*/}
          <div
            className={cn({
              [styles.dark]: theme === "dark",
              [styles.light]: theme === "light"
            })}
          >
            <Outlet />
          </div>
          <Footer className="bdall" />
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
