import { FC, useCallback, useEffect, useState } from "react";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PaginationView from "@components/Pagination/PaginationView";
import TableSearchItem from "@components/Table/TableSearchItem";
import TableViewItem from "@components/Table/TableViewItem";
import Row from "@components/Wrapper/Row";
import useToast from "@hooks/useToast";
import { auditLogEntryInitialState, IAuditLogEntry } from "@interfaces/IAudit";
import { adminService } from "@services/adminService";
import { formatDateTime } from "@utils/date";

const AuditPage: FC = () => {
  const { addToast } = useToast();

  const [itemViewCount, setItemViewCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState([auditLogEntryInitialState]);
  const [lastPage, setLastPage] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [tables, setTables] = useState<string[]>([""]);
  const [selectedTable, setSelectedTable] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const response = await adminService.getAudit(itemViewCount, currentPage, startTime, endTime,
        selectedTable);
      if (response) {
        setTableData(response.data);
        setItemCount(response.count);
        setLastPage(response.lastPage);
      }

      const tableResponse = await adminService.getAuditTables();
      if (tableResponse) {
        setTables(tableResponse);
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  }, [addToast, currentPage, endTime, itemViewCount, selectedTable, startTime]);

  useEffect(() => {
    fetchData();
  }, [itemViewCount, currentPage, fetchData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData();
  };

  const AuditTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>
        <th className="text-center">Таблица</th>
        <th className="text-center">Операция</th>
        <th className="text-center">Изменения</th>
        <th className="text-center">Дата изменения</th>
      </tr>
    );
  };

  const formatValue = (value: string | number | boolean | null) => {
    if (typeof value === "boolean") {
      return value ? "TRUE" : "FALSE";
    }

    if (typeof value === "string" && !isNaN(Date.parse(value))) {
      return formatDateTime(value);
    }

    if (value === null) {
      return "Отсутствует";
    }

    return value ?? "";
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Аудит</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <div>
            <label className="me-3" htmlFor={`startTime`}>Выбрать период с </label>
            <input
              type="date"
              id={`startTime`}
              className="form-control-sm me-3"
              name="startTime"
              onChange={(e) => {
                const selectedDate = e.target.value;
                const formattedDate = new Date(selectedDate).toISOString().split("T")[0];
                setStartTime(formattedDate);
              }}
            />
            <label className="me-3" htmlFor={`endTime`}>по </label>
            <input
              type="date"
              id={`endTime`}
              className="form-control-sm me-3"
              name="endTime"
              onChange={(e) => {
                const selectedDate = e.target.value;
                const formattedDate = new Date(selectedDate).toISOString().split("T")[0];
                setEndTime(formattedDate);
              }}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Применить
            </button>
          </div>
          <div className="mt-3">
            <label className="me-3" htmlFor="tableName">Сортировка по таблице: </label>
            <select
              className="form-control-sm"
              name="tableName"
              onChange={(e) => {
                setSelectedTable(e.target.value);
              }}
              defaultValue={""}
            >
              <option value={""}>
                Все таблицы
              </option>
              {tables.map((item: string, key: number) => (
                <option key={key} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </Row>
        <Row className="mt-3">
          <TableViewItem
            itemViewCount={itemViewCount}
            setItemViewCount={setItemViewCount}
          />
          <TableSearchItem
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Row>
        <div className="table-responsive">
          <table className="table table-striped table-bordered my-0">
            <thead>
            <AuditTableHead />
            </thead>
            <tbody>
            {tableData.map((row: IAuditLogEntry, key: number) => (
              <>
                {row.operation && (
                  <tr key={key}>
                    <td className="align-middle text-center">{getRowNumber(key)}</td>
                    <td className="align-middle text-center">{row.tableName}</td>
                    <td className="align-middle text-center">{row.operation}</td>
                    <td className="align-middle text-center">
                      <div className="table-responsive" style={{ maxWidth: "1200px" }}>
                        <table className="table table-striped table-bordered my-0">
                          <thead>
                          <tr>
                            <th className="align-middle text-center">row</th>
                            {Object.keys(row.changes).map((field: any, key: number) => (
                              <th className="align-middle text-center" key={key}>{field}</th>
                            ))}
                          </tr>
                          </thead>
                          <tbody>
                          {!(row.operation === "INSERT") && (
                            <tr>
                              <td className="align-middle text-center">OLD</td>
                              {Object.keys(row.changes).map((field: any, key: number) => (
                                <td className="align-middle text-center" key={key}>{
                                  formatValue(row.changes[field].oldValue)
                                }</td>
                              ))}
                            </tr>
                          )}
                          <tr>
                            <td className="align-middle text-center">NEW</td>
                            {Object.keys(row.changes).map((field: any, key: number) => (
                              <td className="align-middle text-center" key={key}>{
                                row.changes[field].changed ?
                                  <div className="align-middle text-center text-danger">{
                                    formatValue(row.changes[field].newValue)
                                  }</div> :
                                  <div className="align-middle text-center">{
                                    formatValue(row.changes[field].newValue)
                                  }</div>
                              }</td>
                            ))}
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>

                    <td className="align-middle text-center">
                      {row.changedOn && formatDateTime(row.changedOn)}
                    </td>

                  </tr>
                )}
              </>
            ))}
            </tbody>
            <tfoot>
            <AuditTableHead />
            </tfoot>
          </table>
        </div>
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default AuditPage;