import { ChangeEvent, Dispatch, FC, useId } from "react";

import useTheme from "@hooks/useThemes";
import MDEditor from "@uiw/react-md-editor";
import type { ContextStore } from "@uiw/react-md-editor/src/Context";

interface DescriptionInputProps {
  description: string;
  setDescription: Dispatch<string>;
}

const DescriptionInput: FC<DescriptionInputProps> = ({ description, setDescription }) => {
  const id = useId();
  const { theme } = useTheme();

  const handleChange = (value?: string,
                        event?: ChangeEvent<HTMLTextAreaElement>,
                        state?: ContextStore) => {
    if (event && event.target) {
      setDescription(event.target.value);
    }
  };

  return (
    <div className="col-12">
      <label htmlFor={id}>Описание версии</label>
      <div className="form-floating">
        <MDEditor
          id={id}
          value={description}
          onChange={handleChange}
          data-color-mode={theme === "light" ? "light" : "dark"}
          className="mt-1"
        />
      </div>
    </div>
  );
};

export default DescriptionInput;