import { FC } from "react";

import styles from "./FallingStars.module.scss";

interface FallingStarsProps {
  left: number;
  width: number;
  height: number;
  animationDuration: number;
}

const FallingStars: FC<FallingStarsProps> = ({ left, width, height, animationDuration }) => {
  return (
    <div
      className={styles.star}
      style={{
        left: `${left}%`,
        width: `${width}px`,
        height: `${height}px`,
        animationDuration: `${animationDuration}s`
      }}
    >❉</div>
  );
};

export default FallingStars;
