import { REFRESH_TOKEN_COOKIE, SESSION_TYPE, STORAGE_ACCESS_TOKEN } from "@core/constants";

type CreateSessionCookies = {
  token?: string
}
export const createSession = (params: CreateSessionCookies) => {
  const { token } = params;

  if (token) {
    const sessionType = localStorage.getItem(SESSION_TYPE);
    if (sessionType === "local") {
      localStorage.setItem(STORAGE_ACCESS_TOKEN, token);
    } else {
      sessionStorage.setItem(STORAGE_ACCESS_TOKEN, token);
    }
  }
};

export const removeSession = () => {
  localStorage.clear();
  sessionStorage.clear();
  destroyCookie(REFRESH_TOKEN_COOKIE);
};

export const getAccessToken = () => {
  let token = localStorage.getItem(STORAGE_ACCESS_TOKEN) ||
    sessionStorage.getItem(STORAGE_ACCESS_TOKEN);
  if (!token) {
    token = "";
  }
  return token;
};

const destroyCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};
