import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";

interface CompanySelectProps {
  companies: any;
  setCompanyID: Dispatch<SetStateAction<number>>;
}

const CompanySelect: FC<CompanySelectProps> = ({ companies, setCompanyID }) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCompanyID(Number(e.target.value));
  };

  return (
    <div className="col-12">
      <label>Компания </label>
      <div className="input-group">
        <div className="input-group-text">
          <i className="bi bi-person-fill"></i>
        </div>
        <select
          className="form-select"
          aria-label="Default select example"
          name="company_id"
          onChange={handleChange}
          defaultValue={-6}
        >
          <option value={-6} disabled>
            Выберите компанию
          </option>
          {companies.map((item: any, key: any) => (
            <option key={key} value={item.ID}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CompanySelect;