import { FC, useContext } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import lamp from "@assets/lamp.svg";
import moon from "@assets/moon.svg";
import FallingStarIcon from "@components/FallingStars/FallingStarIcon";
import AuthContext from "@context/AuthContext";
import {
  ACCESS_LEVEL_USER,
  COMPANY_PAGE_LINK,
  DOWNLOAD_PAGE_LINK,
  LICENSE_PAGE_LINK,
  MAIN_PAGE_LINK,
  PROFILE_PAGE_LINK
} from "@core/constants";
import useSession from "@hooks/useSession";
import useTheme from "@hooks/useThemes";
import { isHolidaySeason } from "@utils/holidays";

import styles from "./Header.module.scss";
import Navbar from "./Navbar";
import NavItem from "./NavItem";

import LogoIcon from "../Icons/LogoIcon";
import PeopleCircleIcon from "../Icons/PeopleCircleIcon";

const Header: FC = () => {
  const { t } = useTranslation();
  const user = useContext(AuthContext).user;
  const role = user.accessLevel;

  const { theme, setTheme } = useTheme();
  const { signOut } = useSession();
  const handleLogout = () => {
    signOut();
  };

  const handleChangeTheme = () => {
    if (theme !== "dark") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const headerStyle = {
    borderBottom: role && role === ACCESS_LEVEL_USER ? "none" : "1px solid #000000"
  };

  return (
    <header
      className={`${cn(styles.root, {
        [styles.dark]: theme === "dark",
        [styles.light]: theme === "light"
      })} navbar navbar-expand-lg`}
      style={headerStyle}
    >
      <div className={cn("container-fluid", isHolidaySeason() ? "ps-0" : "")}>
        <Link className="navbar-brand" to={MAIN_PAGE_LINK}>
          <LogoIcon height={60} />
        </Link>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {role && role === ACCESS_LEVEL_USER ? (
            <Navbar>
              <NavItem link={COMPANY_PAGE_LINK} text={t("company-text")} />
              <NavItem link={MAIN_PAGE_LINK || LICENSE_PAGE_LINK} text={t("license-text")} />
              <NavItem link={DOWNLOAD_PAGE_LINK} text={t("download-text")} />
            </Navbar>
          ) : (
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item"></li>
              <li className="nav-item"></li>
            </ul>
          )}

          <form className="d-flex me-2" role="search">
            <input
              className="form-control me-2"
              type="search"
              name="search-text"
              placeholder={t("search-text")}
            />
            <button
              className={cn(
                theme === "light" ?
                  "btn btn-outline-primary" :
                  "btn btn-outline-secondary"
              )}
              type="submit"
            >
              {t("search-text")}
            </button>
          </form>

          <div className={"nav-item dropdown no-arrow me-2"}>
            <button
              className="dropdown-toggle nav-link"
              aria-expanded="false"
              data-bs-toggle="dropdown"
            >
              <span
                className="d-none d-lg-inline me-2 text-gray-600 small"
                style={{ fontSize: "1em" }}
              >
                {user && user.email}
              </span>
              <PeopleCircleIcon />
            </button>
            <ul className={cn("dropdown-menu")}>
              <li className="nav-item">
                <Link
                  className="dropdown-item"
                  to={PROFILE_PAGE_LINK}
                >
                  &nbsp;{t("profile-text")}
                </Link>
              </li>
              <li className="nav-item">
                <hr className="dropdown-divider" />
              </li>
              <li className="nav-item">
                <button
                  className="dropdown-item"
                  onClick={handleLogout}
                >
                  &nbsp;{t("exit-text")}
                </button>
              </li>
            </ul>
          </div>

          <img
            src={theme === "dark" ? moon : lamp}
            alt="lamp"
            width={theme === "dark" ? 18 : 25}
            onClick={handleChangeTheme}
          />

          {isHolidaySeason() && <FallingStarIcon />}
        </div>
      </div>
      <hr
        style={{
          height: "1px",
          border: "none",
          backgroundColor: "black",
          color: "black",
          padding: 0,
          margin: 0
        }} />
    </header>
  );
};

export default Header;
