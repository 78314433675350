import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useId, useRef, useState } from "react";

import { STATUS_CREATED } from "@core/constants";
import useStorage from "@hooks/useStorage";
import useToast from "@hooks/useToast";
import { adminService } from "@services/adminService";

interface UploadProgramInputProps {
  isFileUploaded: boolean;
  setIsFileUploaded: Dispatch<SetStateAction<boolean>>;
  setUploadedFileID: Dispatch<SetStateAction<number>>;
  isFileResetForm: boolean;
  setIsFileResetForm: Dispatch<SetStateAction<boolean>>;
}

const UploadProgramInput: FC<UploadProgramInputProps> = ({
                                                           isFileUploaded,
                                                           setIsFileUploaded,
                                                           setUploadedFileID,
                                                           isFileResetForm,
                                                           setIsFileResetForm
                                                         }) => {
    const id = useId();
    const { addToast } = useToast();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
    const [fileSize, setFileSize] = useState<number>(0);
    const [isUploadProcess, setIsUploadProcess] = useState<boolean>(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const [prevFileName, setPrevFileName] = useStorage<string>("prevFileName", "");
    const [fileTimestamp, setFileTimestamp] = useStorage<string>("fileTimestamp", "");
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const oneHour = 3600000;

    const resetFile = useCallback(() => {
      setSelectedFile(null);
      setFileSize(0);
      setIsUploadProcess(false);
      setIsFileLoaded(false);
      setUploadProgress(0);
      setIsFileUploaded(false);
      setPrevFileName("");
      setFileTimestamp("");
    }, [setFileTimestamp, setIsFileUploaded, setPrevFileName]);

    useEffect(() => {
      if (isFileResetForm) {
        setIsFileResetForm(false);
        resetFile();
        if (fileRef.current) {
          fileRef.current.value = "";
        }
      }
    }, [isFileResetForm, resetFile, setIsFileResetForm]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      resetFile();

      if (file) {
        setSelectedFile(file);
        setIsFileLoaded(true);

        const fileSizeInKB = (file.size / 1024).toFixed(0);
        setFileSize(Number(fileSizeInKB));
      }
    };

    const handleUpload = async (e: any) => {
      e.preventDefault();

      if (selectedFile) {
        const file = selectedFile;//fileInput.files[0];
        const chunkSize = 1024 * 1024 * 4; // 4 MB chunks

        let testDir: string = "uploadChunk";
        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let startTime = Date.now();

        setIsUploadProcess(true);
        setUploadProgress(0);
        setIsFileUploaded(false);
        setIsFileUploading(true);

        while (currentChunk < totalChunks) {
          const start = currentChunk * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const chunk = file.slice(start, end);

          const formData = new FormData();
          formData.append("chunk", chunk);
          formData.append("totalChunks", String(totalChunks));
          formData.append("currentChunk", String(currentChunk));
          formData.append("tempDir", testDir);
          formData.append("fileName", file.name);

          try {
            await adminService.empty();

            const response = await adminService.uploadFile(formData);
            if (response.data) {
              const uploadProgress = (((currentChunk + 1) / totalChunks) * 100).toFixed(0);
              setUploadProgress(Number(uploadProgress));

              if (response.status === 200) {
                testDir = response.data.tempDir;
              }

              if (response.status === STATUS_CREATED) {
                setIsFileUploaded(true);
                setUploadProgress(100);
                console.log(uploadProgress);
                setUploadedFileID(Number(response.data.id));
                addToast(response.data.message, "success");
              }

              currentChunk++;
            }
          } catch (e: any) {
            if (e?.response) {
              if (e.response.status >= 400 && e.response.status < 500) {
                addToast(e.response.data.message, "warning");
              } else if (e.response.status >= 500) {
                addToast(e.response.data.message, "error");
              }
            }

            break;
          }
        }

        setIsFileUploading(false);

        let lastTime = Date.now();
        console.log("Время загрузки файла: ", (lastTime - startTime));

        if (isFileLoaded) {
          setPrevFileName(file.name);
          setFileTimestamp(lastTime.toString());
          resetFileTimeout();
        }
      } else {
        addToast("файл не выбран", "warning");
      }
    };

    const resetFileTimeout = useCallback(() => {
      if (fileTimestamp !== "") {
        setIsFileResetForm(true);
        setIsFileUploaded(false);
        addToast(`Время ожидания у загруженного файла ${prevFileName} закончилось`, "warning");
      }
    }, [addToast, fileTimestamp, prevFileName, setIsFileResetForm, setIsFileUploaded]);

    useEffect(() => {
      const storedTime = fileTimestamp;

      if (storedTime) {
        const now = Date.now();
        const timePassed = now - parseInt(storedTime);

        if (timePassed >= oneHour) {
          resetFileTimeout();
        } else {
          setTimeout(() => {
            resetFileTimeout();
          }, oneHour - timePassed);
        }
      }
    }, [fileTimestamp, resetFileTimeout]);

    return (
      <div className="col-12">
        <label htmlFor={`file-upload-${id}`}>Файл</label>
        <div className="input-group">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleUpload}
            disabled={isFileUploaded || !isFileLoaded || isFileUploading}
          >
            Загрузить
          </button>
          <input
            type="file"
            className="form-control"
            id={`file-upload-${id}`}
            onChange={handleFileChange}
            ref={fileRef}
          />

        </div>
        {isFileLoaded ? (
          <div className="mt-3">
            <p className="d-inline mt-3 me-3">Размер файла: {fileSize} КБ</p>

            {isUploadProcess && (
              <div className="d-flex mt-3 align-items-center">
                <p className="m-0 me-3" style={{ whiteSpace: "nowrap" }}>Статус загрузки: </p>
                {!isFileUploaded ? (
                  <div className="progress" style={{ width: "100%" }}>
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>{uploadProgress}%</div>
                  </div>
                ) : (
                  <div>файл загружен</div>
                )}
              </div>
            )}
          </div>
        ) : (
          isFileUploaded && (
            <div className="mt-3">
              <p className="d-inline mt-3 me-3">Предыдущий файл: {prevFileName}
                <span className="text-success"> (загружен)</span>
              </p>
            </div>
          )
        )}
      </div>
    )
      ;
  }
;

export default UploadProgramInput;