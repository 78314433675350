import { FC, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PaginationView from "@components/Pagination/PaginationView";
import Row from "@components/Wrapper/Row";
import {
  CONTRACT_ORDER_CREATE_PAGE_LINK,
  CONTRACT_ORDERS_BY_CONTRACT_ID_PAGE_LINK,
  CONTRACT_PAGE_LINK,
  getContractOrdersPageByContractID
} from "@core/constants";
import useToast from "@hooks/useToast";
import { IOrder } from "@interfaces/IContract";
import { adminService } from "@services/adminService";
import { formatDateSimple } from "@utils/date";
import { queryStringBuilder } from "@utils/query";

const ContractOrdersTableHead: FC = () => {
  return (
    <tr>
      <th className="text-center">№</th>
      <th className="text-center">Номер заявки</th>
      <th className="text-center">Количество лицензий</th>
      <th className="text-center">Лицензий осталось</th>
      <th className="text-center">Экспорт номеров</th>
      <th className="text-center">Дата заключения заявки</th>
    </tr>
  );
};

const ContractOrdersPage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [search] = useSearchParams();

  const [tableData, setTableData] = useState<IOrder[]>([]);
  const [contractNumber, setContractNumber] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");

  const [itemViewCount, setItemViewCount] = useState(10);
  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const getRowNumber = (index: number) => (currentPage - 1) * itemViewCount + index + 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getContractOrdersByContractID(Number(id), search.toString());
        if (response.data !== null) {
          setTableData(response.data);
          setItemCount(response.count);
          setLastPage(response.lastPage);
          setContractNumber(response.contractNumber);
          setCompanyName(response.companyName);
        } else {
          setTableData([]);
        }
      } catch (e: any) {
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };
    fetchData();
  }, [addToast, search]);

  useEffect(() => {
    const queryString = queryStringBuilder({
      limit: itemViewCount,
      page: currentPage
    });

    navigate(queryString);
  }, [itemViewCount, currentPage, navigate]);

  useEffect(() => {
    const _itemViewCount = search.get("limit");
    if (_itemViewCount && Number(_itemViewCount) > 0 && Number(_itemViewCount) <= 100) {
      setItemViewCount(Number(_itemViewCount));
    }

    const _page = search.get("page");
    if (_page) {
      if (_page && Number(_page) > 0 && Number(_page)) {
        setCurrentPage(Number(_page));
      }
    }
  }, []);

  const handleSwitchToLastPage = () => {
    if (location.pathname === getContractOrdersPageByContractID(Number(id))) {
      navigate(CONTRACT_PAGE_LINK);
    } else {
      navigate(getContractOrdersPageByContractID(Number(id)));
    }
  };

  const handleSwitchCreateContractOrderPage = () => {
    if (location.pathname === getContractOrdersPageByContractID(Number(id))) {
      navigate(CONTRACT_ORDER_CREATE_PAGE_LINK);
    } else {
      navigate(CONTRACT_ORDERS_BY_CONTRACT_ID_PAGE_LINK);
    }
  };

  const handleButtonExport = async (contractID: number) => {
    try {
      const response = await adminService.exportContractOrderLicensesNumbersByContractOrderID(contractID);
      const contentDisposition = response.headers["Content-Disposition"];
      let fileName = "license_numbers_contract_id_" + String(contractID);

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Компания {companyName}, номер контракта: {contractNumber}</h6>
      </CardHeader>
      <Row>
        <div className="col-12">
          <button
            type="submit"
            onClick={handleSwitchToLastPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Вернуться
          </button>
          <button
            type="submit"
            onClick={handleSwitchCreateContractOrderPage}
            className="btn btn-primary btn-sm px-4 mt-4 ms-4"
            style={{ marginLeft: "auto" }}
          >
            Добавить заявку к контракту
          </button>
          <hr />
        </div>
      </Row>
      <CardBody>
        <div className="table-responsive table mt-2">
          <table className="table table-striped table-bordered my-0">
            <thead>
            <ContractOrdersTableHead />
            </thead>
            <tbody>
            {tableData.length > 0 && tableData.map((row: IOrder, key: number) => (
              <tr key={row.OrderID}>
                <td className="align-middle text-center">{getRowNumber(key)}</td>
                <td className="align-middle text-center">{row.OrderNumber}</td>
                <td className="align-middle text-center">{row.OrderLicCount}</td>
                <td className="align-middle text-center">{row.OrderLicAvailable}</td>
                <td className="align-middle text-center">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleButtonExport(row.OrderID)}
                  >
                    Скачать
                  </button>
                </td>

                <td className="align-middle text-center">{formatDateSimple(row.OrderDateOfConclusion)}</td>
              </tr>
            ))}
            </tbody>
            <tfoot>
            <ContractOrdersTableHead />
            </tfoot>
          </table>
        </div>
        <Row>
          <PaginationView
            currentPage={currentPage}
            lastPage={lastPage}
            itemViewCount={itemViewCount}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default ContractOrdersPage;