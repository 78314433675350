import { ChangeEvent, FC, useEffect, useState } from "react";

import cn from "classnames";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import Col from "@components/Wrapper/Col/Col";
import Row from "@components/Wrapper/Row";
import {
  getSoftwareInfoPageLinkByID,
  getUserInfoPageLinkByID,
  SOFTWARE_PAGE_LINK,
  STATUS_CREATED
} from "@core/constants";
import useTheme from "@hooks/useThemes";
import useToast from "@hooks/useToast";
import {
  ISoftwareVersionBySoftwareID,
  ISoftwareVersionUser,
  softwareVersionBySoftwareIDInitialState
} from "@interfaces/ISoftware";
import OperationSystemSelect from "@module/UploadPage/OpetationSystemSelect";
import AdminAccessWrapper from "@permission/AccessControlWrapper";
import { adminService } from "@services/adminService";
import styles from "@styles/MarkDown.module.scss";
import MDEditor from "@uiw/react-md-editor";
import type { ContextStore } from "@uiw/react-md-editor/src/Context";
import { formatDateTime } from "@utils/date";
import { bytesToMB, compareRows } from "@utils/utils";

const SoftwareInfoPage: FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const { theme } = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [osID, setOsID] = useState<number>(1);
  const [description, setDescription] = useState<string>("");
  const [changeLog, setChangeLog] = useState<string>("");

  const [softwareInfo, setSoftwareInfo] =
    useState<ISoftwareVersionBySoftwareID>(softwareVersionBySoftwareIDInitialState);

  const [oldSoftwareInfo, setOldSoftwareInfo] =
    useState<ISoftwareVersionBySoftwareID>(softwareVersionBySoftwareIDInitialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminService.getSoftwareVersionInfoBySoftwareID(Number(id));

        if (response) {
          setSoftwareInfo(response);
          setOldSoftwareInfo(response);
          setChangeLog(response.changeLog);
          setDescription(response.description);
        }
      } catch (e: any) {
        setSoftwareInfo(oldSoftwareInfo);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    };

    fetchData();
  }, [id, addToast]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitchPage = () => {
    if (location.pathname === getSoftwareInfoPageLinkByID(Number(id))) {
      navigate(SOFTWARE_PAGE_LINK);
    } else {
      navigate(getSoftwareInfoPageLinkByID(Number(id)));
    }
  };

  const handleEditButtonChange = () => {
    setIsEditMode(true);
  };

  const handleCloseEditButtonChange = () => {
    setIsEditMode(false);
    setSoftwareInfo(oldSoftwareInfo);
  };

  const handleSaveRowChanges = async () => {
    setIsEditMode(false);

    const changedFields = compareRows(oldSoftwareInfo, softwareInfo);
    const formData = {};

    if (changedFields.version) {
      Object.assign(formData, { version: changedFields.version });
    }

    if (changedFields.description) {
      Object.assign(formData, { description: changedFields.description });
    }

    if (changedFields.changeLog) {
      Object.assign(formData, { changeLog: changedFields.changeLog });
    }

    if (changedFields.fileName) {
      Object.assign(formData, { fileName: changedFields.fileName });
    }

    if (changedFields.releaseDate) {
      Object.assign(formData, { releaseDate: changedFields.releaseDate });
    }

    if (changedFields.branch) {
      Object.assign(formData, { branch: changedFields.branch });
    }

    if (changedFields.hasOwnProperty("isFullRelease")) {
      Object.assign(formData, { isFullRelease: changedFields.isFullRelease });
    }

    if (changedFields.hasOwnProperty("isActive")) {
      Object.assign(formData, { isActive: changedFields.isActive });
    }

    if (changedFields.hasOwnProperty("approved")) {
      Object.assign(formData, { approved: changedFields.approved });
    }

    if (changedFields.hasOwnProperty("visible")) {
      Object.assign(formData, { visible: changedFields.visible });
    }

    if (!(Object.entries(formData).length === 0)) {
      try {
        const response = await adminService.updateSoftwareVersion(softwareInfo.ID, formData);
        if (response.status === STATUS_CREATED) {
          setOldSoftwareInfo(softwareInfo);
          addToast(response.data.message, "success");
        }
      } catch (e: any) {
        setSoftwareInfo(oldSoftwareInfo);
        if (e.response.status >= 400 && e.response.status < 500) {
          addToast(e.response.data.message, "warning");
        } else if (e.response.status >= 500) {
          addToast(e.response.data.message, "error");
        }
      }
    }
  };

  useEffect(() => {
    setSoftwareInfo(prevState => ({
      ...prevState,
      operationSystemID: osID
    }));
  }, [osID]);

  useEffect(() => {
    setSoftwareInfo(prevData => ({
      ...prevData,
      description: description
    }));
  }, [description]);

  useEffect(() => {
    setSoftwareInfo(prevData => ({
      ...prevData,
      changeLog: changeLog
    }));
  }, [changeLog]);

  const handleOnChangeField = (e: any) => {
    let value: string;

    const { name: fieldName } = e.target;

    if (fieldName === "isFullRelease"
      || fieldName === "isActive"
      || fieldName === "approved"
      || fieldName === "visible") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setSoftwareInfo(prevData => ({
      ...prevData,
      [fieldName]: value
    }));

  };

  const UserTableHead: FC = () => {
    return (
      <tr>
        <th className="text-center">№</th>
        <th className="text-center">Имя</th>
        <th className="text-center">Email</th>
        <th className="text-center">Дата</th>
      </tr>
    );
  };

  const handleChangeDescription = (_value?: string,
                                   event?: ChangeEvent<HTMLTextAreaElement>,
                                   _state?: ContextStore) => {
    if (event && event.target) {
      setDescription(event.target.value);
    }
  };

  const handleChangeChangeLog = (_value?: string,
                                 event?: ChangeEvent<HTMLTextAreaElement>,
                                 _state?: ContextStore) => {
    if (event && event.target) {
      setChangeLog(event.target.value);
    }
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Загруженное ПО версия: {softwareInfo.version}</h6>
      </CardHeader>
      <AdminAccessWrapper>
        <Row>
          <div className="col-12">
            <button
              type="submit"
              onClick={handleSwitchPage}
              className="btn btn-primary btn-sm px-4 mt-4 ms-4"
              style={{ marginLeft: "auto" }}
            >
              Вернуться
            </button>
            {isEditMode && isEditMode ? (
              <>
                <button
                  type="submit"
                  onClick={handleSaveRowChanges}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Сохранить изменения
                </button>
                <button
                  type="submit"
                  onClick={handleCloseEditButtonChange}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Отменить редактирование
                </button>
              </>

            ) : (
              <>
                <button
                  type="submit"
                  onClick={handleEditButtonChange}
                  className="btn btn-primary btn-sm px-4 mt-4 ms-4"
                  style={{ marginLeft: "auto" }}
                >
                  Редактировать
                </button>
              </>
            )}
            <hr />
          </div>
        </Row>
      </AdminAccessWrapper>
      <CardBody>

        <Card>
          <CardHeader>Сведения о ПО</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="software_name">
                    <strong>Название</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="software_name"
                    placeholder="Название ПО"
                    name="software_name"
                    value={softwareInfo.name}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="version">
                    <strong>Версия</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="version"
                    placeholder="Версия"
                    name="version"
                    value={softwareInfo.version}
                    disabled={!isEditMode}
                    onChange={handleOnChangeField}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <OperationSystemSelect
                    osID={softwareInfo.operationSystemID}
                    setOsID={setOsID}
                    disabled
                  />
                </div>
              </Col>
              <Col>

                <div className="mb-3">
                  <Link to={getUserInfoPageLinkByID(softwareInfo.uploaderID)}>

                    <label className="form-label" htmlFor="uploader">
                      <strong>Загрузил</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="uploader"
                      placeholder="Загрузил"
                      name="uploader"
                      value={softwareInfo.uploader}
                      disabled
                    />
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {!isEditMode ? (
                  <div className="mb-3">
                    <label className="form-label" htmlFor="is_active">
                      <strong>Активная версия</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="is_active"
                      placeholder="Активная версия"
                      name="isActive"
                      value={softwareInfo.isActive ? "Да" : "Нет"}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label htmlFor={`isActive-${id}`} className="me-2">
                      <strong>Активная версия</strong>
                    </label>
                    <div className="form-control form-switch">
                      <span className="ms-0">Да? </span>
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        name="isActive"
                        id={`isActive-${id}`}
                        onChange={(e) => handleOnChangeField(e)}
                        checked={softwareInfo.isActive}
                      />
                    </div>
                  </div>
                )}

              </Col>
              <Col>
                {!isEditMode ? (
                  <div className="mb-3">
                    <label className="form-label" htmlFor="is_full_release">
                      <strong>Полный релиз</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="is_full_release"
                      placeholder="Полный релиз"
                      name="is_full_release"
                      value={softwareInfo.isFullRelease ? "Да" : "Нет"}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label htmlFor={`is-isFullRelease-${id}`} className="me-2">
                      <strong>Полный? </strong>
                    </label>
                    <div className="form-control form-switch">
                      <span className="ms-0">Да? </span>
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        name="isFullRelease"
                        id={`is-isFullRelease-${id}`}
                        onChange={(e) => handleOnChangeField(e)}
                        checked={softwareInfo.isFullRelease}
                      />
                    </div>
                  </div>
                )}

              </Col>
            </Row>

            <Row>
              <Col>
                {!isEditMode ? (
                  <div className="mb-3">
                    <label className="form-label" htmlFor="approved">
                      <strong>Approved</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="approved"
                      placeholder="approved"
                      name="approved"
                      value={softwareInfo.approved ? "Да" : "Нет"}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label htmlFor={`approved-${id}`} className="me-2">
                      <strong>Approved? </strong>
                    </label>
                    <div className="form-control form-switch">
                      <span className="ms-0">Да? </span>
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        name="approved"
                        id={`approved-${id}`}
                        onChange={(e) => handleOnChangeField(e)}
                        checked={softwareInfo.approved}
                      />
                    </div>
                  </div>
                )}

              </Col>
              <Col>
                {!isEditMode ? (
                  <div className="mb-3">
                    <label className="form-label" htmlFor="visible">
                      <strong>Visible</strong>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="visible"
                      placeholder="Visible"
                      name="visible"
                      value={softwareInfo.visible ? "Да" : "Нет"}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label htmlFor={`visible-${id}`} className="me-2">
                      <strong>Visible? </strong>
                    </label>
                    <div className="form-control form-switch">
                      <span className="ms-0">Да? </span>
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        name="visible"
                        id={`visible-${id}`}
                        onChange={(e) => handleOnChangeField(e)}
                        checked={softwareInfo.visible}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="description">
                    <strong>Описание</strong>
                  </label>
                  {!isEditMode ? (
                    <Card>
                      <CardBody>
                        <MDEditor.Markdown
                          className={cn(theme === "light" ? styles.root_light : styles.root_dark)}
                          source={softwareInfo.description}
                          style={{
                            backgroundColor: theme === "light" ? "white" : "#212529",
                            color: theme === "light" ? "black" : "white" // Черный текст
                          }}
                        />
                      </CardBody>
                    </Card>
                  ) : (
                    <MDEditor
                      value={description}
                      onChange={handleChangeDescription}
                      data-color-mode={theme === "light" ? "light" : "dark"}
                      className="mt-1"
                      height={"auto"}
                    />
                  )}

                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="changeLog">
                    <strong>Список изменений</strong>
                  </label>
                  {!isEditMode ? (
                    <Card>
                      <CardBody>
                        <MDEditor.Markdown
                          className={cn(theme === "light" ? styles.root_light : styles.root_dark)}
                          source={softwareInfo.changeLog}
                          style={{
                            backgroundColor: theme === "light" ? "white" : "#212529",
                            color: theme === "light" ? "black" : "white" // Черный текст
                          }}
                        />
                      </CardBody>
                    </Card>
                  ) : (
                    <MDEditor
                      value={changeLog}
                      onChange={handleChangeChangeLog}
                      data-color-mode={theme === "light" ? "light" : "dark"}
                      className="mt-1"
                      height={"auto"}
                    />
                  )}
                </div>
              </Col>
            </Row>

            {softwareInfo.libs && (
              <Row>
                <Col>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="libs">
                      <strong>Библиотеки</strong>
                    </label>
                    <Card>
                      <CardBody>
                        {softwareInfo.libs.split(",").map((item, index) => (
                          <div key={index}>{item}</div>
                        ))}
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="releaseDate">
                    <strong>Дата релиза</strong>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    id="releaseDate"
                    placeholder="Дата релиза"
                    name="releaseDate"
                    onChange={handleOnChangeField}
                    value={softwareInfo.releaseDate}
                    // value={selDate}
                    disabled={!isEditMode}
                  />
                </div>
              </Col>

              <Col>
                <></>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="updatedAt">
                    <strong>Дата последнего обновления записи</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="updatedAt"
                    placeholder="Дата последнего обновления записи"
                    name="updatedAt"
                    value={softwareInfo.updatedAt ? formatDateTime(softwareInfo.updatedAt) : ""}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="created_at">
                    <strong>Дата создания записи</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="created_at"
                    placeholder="Дата создания записи"
                    name="created_at"
                    value={softwareInfo.createdAt ? formatDateTime(softwareInfo.createdAt) : ""}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mt-3">
          <CardHeader>Сведения о файле</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="fileName">
                    <strong>Имя файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="fileName"
                    placeholder="Имя файла"
                    name="fileName"
                    value={softwareInfo.file.name}
                    onChange={handleOnChangeField}
                    disabled={!isEditMode}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_old_name">
                    <strong>Начальное имя файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_old_name"
                    placeholder="Начальное имя файла"
                    name="file_old_name"
                    value={softwareInfo.file.oldName ? softwareInfo.file.oldName : "Отсутствует"}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_type">
                    <strong>Тип файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_type"
                    placeholder="Тип файла"
                    name="file_type"
                    value={softwareInfo.file.type}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_size">
                    <strong>Размер файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_size"
                    placeholder="Размер файла"
                    name="file_size"
                    value={bytesToMB(softwareInfo.file.size) + " МБ"}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_crc_32">
                    <strong>CRC32 Файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_crc_32"
                    placeholder="CRC32 Файла"
                    name="file_crc_32"
                    value={"0x" + softwareInfo.file.crc32.toString(16)}
                    disabled
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_download_count">
                    <strong>Количество скачиваний</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_download_count"
                    placeholder="Количество скачиваний"
                    name="file_download_count"
                    value={softwareInfo.file.downloadCount}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="mb-3">
                  <label className="form-label" htmlFor="file_sha256">
                    <strong>SHA256 Файла</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="file_sha256"
                    placeholder="SHA256 Файла"
                    name="file_sha256"
                    value={softwareInfo.file.sha256 && softwareInfo.file.sha256}
                    disabled
                  />
                </div>
              </Col>
            </Row>

            {softwareInfo.file.users.length > 0 && (
              <Row>
                <Col>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="created_at">
                      <strong>Пользователи, которые скачали файл</strong>
                    </label>
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered my-0">
                        <thead>
                        <UserTableHead />
                        </thead>
                        <tbody>
                        {softwareInfo.file.users.map((row: ISoftwareVersionUser, key: number) => (
                          <tr key={row.id}>
                            <td className={"align-middle text-center"}>{key}</td>
                            <td className={"align-middle text-center"}>
                              <Link to={getUserInfoPageLinkByID(row.id)}>{row.name + " " + row.surname}</Link>
                            </td>
                            <td className={"align-middle text-center"}>{row.email}</td>
                            <td
                              className={"align-middle text-center"}>{row.downloadDate && formatDateTime(row.downloadDate)}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

          </CardBody>
        </Card>

      </CardBody>
    </Card>
  )
    ;
};

export default SoftwareInfoPage;