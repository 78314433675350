import { FC, useEffect, useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Card from "@components/Card/Card";
import CardBody from "@components/Card/CardBody";
import CardHeader from "@components/Card/CardHeader";
import PageNavigate from "@components/Navigate/PageNavigate";
import Col from "@components/Wrapper/Col/Col";
import Container from "@components/Wrapper/Container";
import Row from "@components/Wrapper/Row";
import { SOFTWARE_CREATE_PAGE_LINK, SOFTWARE_PAGE_LINK } from "@core/constants";
import useStorage, { deleteStorageKey } from "@hooks/useStorage";
import useToast from "@hooks/useToast";
import ChangeLogInput from "@module/UploadPage/ChangeLogInput";
import DescriptionInput from "@module/UploadPage/DescriptionInput";
import IsFullReleaseCheckbox from "@module/UploadPage/IsFullReleaseCheckbox";
import IsUploadErrorCheckbox from "@module/UploadPage/IsUploadErrorCheckbox";
import OperationSystemSelect from "@module/UploadPage/OpetationSystemSelect";
import ProgramSelect from "@module/UploadPage/ProgramSelect";
import ReleaseDateInput from "@module/UploadPage/ReleaseDateInput";
import SoftwareSelect from "@module/UploadPage/SoftwareSelect";
import UploadProgramInput from "@module/UploadPage/UploadProgramInput";
import VersionProgramInput from "@module/UploadPage/VersionProgramInput";
import { adminService } from "@services/adminService";

const SoftwareUploadPage: FC = () => {
  const { addToast } = useToast();

  const [softwareID, setSoftwareID] = useStorage<number>("softwareID", 1);
  const [softAppID, setSoftAppID] = useStorage<number>("softAppID", 1);
  const [osID, setOsID] = useStorage<number>("osID", 1);
  const [versionProgram, setVersionProgram] = useStorage<string>("versionProgram", "");
  const [description, setDescription] = useStorage<string>("description", "");
  const [changeLog, setChangeLog] = useStorage<string>("changeLog", "");
  const [isFileUploaded, setIsFileUploaded] = useStorage<boolean>("isFileUploaded", false);
  const [isCheckedSubmitButton, setIsCheckedSubmitButton] = useState<boolean>(false);
  const [uploadedFileID, setUploadedFileID] = useStorage<number>("uploadedFileID", 0);
  const [releaseDate, setReleaseDate] = useStorage<string>("releaseDate", "");
  const [isFullRelease, setIsFullRelease] = useStorage<boolean>("isFullRelease", true);
  const [branch, setBranch] = useStorage<string>("branch", "");
  const [sha256Hash, setSha256Hash] = useStorage("sha256Hash", "");

  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [numUploadID, setNumUploadID] = useState<number>(0);
  const [isFileResetForm, setIsFileResetForm] = useState<boolean>(false);

  useEffect(() => {
    if ((isFileUploaded === true || isUploadError)
      && description !== ""
      && changeLog !== ""
      && releaseDate !== ""
      && branch !== ""
      && sha256Hash !== "") {
      setIsCheckedSubmitButton(false);
    } else {
      setIsCheckedSubmitButton(true);
    }
  }, [isFileUploaded, isUploadError, description, changeLog, releaseDate, branch, sha256Hash]);

  const isFormComplete = () => {
    return [versionProgram, description, changeLog, releaseDate, branch, sha256Hash]
        .every(field => field.trim() !== "")
      && isFileUploaded;
  };

  const getMissingFields = () => {
    const missingFields = [];
    if (!versionProgram.trim()) missingFields.push("версия");
    if (!branch.trim()) missingFields.push("ветка");
    if (!sha256Hash.trim()) missingFields.push("sha256");
    if (!description.trim()) missingFields.push("описание версии");
    if (!changeLog.trim()) missingFields.push("список изменений");
    if (!releaseDate.trim()) missingFields.push("дата релиза");
    if (!isFileUploaded) missingFields.push("файл");
    return missingFields.join(", ");
  };

  const [resetFormStorage, setResetFormStorage] = useState<boolean>(false);
  useEffect(() => {
    if (resetFormStorage) {
      deleteStorageKey("softwareID");
      deleteStorageKey("softAppID");
      deleteStorageKey("osID");
      deleteStorageKey("versionProgram");
      deleteStorageKey("description");
      deleteStorageKey("changeLog");
      deleteStorageKey("uploadedFileID");
      deleteStorageKey("releaseDate");
      deleteStorageKey("isFullRelease");
      deleteStorageKey("branch");
      deleteStorageKey("sha256Hash");
      deleteStorageKey("isFileUploaded");
      deleteStorageKey("fileTimestamp");
      deleteStorageKey("prevFileName");
      setResetFormStorage(false);
    }
  }, [resetFormStorage]);

  const resetFormToDefaultValues = () => {
    setSoftwareID(1);
    setSoftAppID(1);
    setOsID(1);
    setVersionProgram("");
    setDescription("");
    setChangeLog("");
    setUploadedFileID(0);
    setReleaseDate("");
    setIsFullRelease(true);
    setBranch("");
    setSha256Hash("");
    setIsFileUploaded(false);
    setResetFormStorage(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let fileID = 0;
    if (isUploadError)
      fileID = numUploadID;
    else
      fileID = uploadedFileID;

    const formData = {
      software_id: softwareID,
      os_id: osID,
      soft_app_id: softAppID,
      version: versionProgram,
      release_date: releaseDate,
      description: description,
      change_log: changeLog,
      file_id: fileID,
      is_active: true,
      is_full_release: isFullRelease,
      branch: branch,
      sha_256_hash: sha256Hash
    };

    try {
      const response: any = await adminService.createSoftwareUpdate(formData);
      if (response) {
        addToast(response.data.message, "success");

        setIsFileResetForm(true);
        setIsFileUploaded(false);
        resetFormToDefaultValues();
      }
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");

        setIsFileResetForm(true);
        setIsFileUploaded(false);
      }
    }
  };

  return (
    <Card style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 0, borderRadius: 0 }}>
      <CardHeader style={{ borderRadius: 0 }}>
        <h6 className="mt-2">Загрузка обновления ПО</h6>
      </CardHeader>
      <PageNavigate
        current={SOFTWARE_CREATE_PAGE_LINK}
        to={SOFTWARE_PAGE_LINK}
        title={"Вернуться"}
      />
      <CardBody>
        <Container>
          <Row>
            <Col>
              <div className="form-left h-100 py-5 px-5">
                <form className="row g-3">

                  <SoftwareSelect
                    isLoadingMode={true}
                    softwareID={softwareID}
                    setSoftwareID={setSoftwareID}
                  />
                  <ProgramSelect
                    isLoadingMode={true}
                    softAppID={softAppID}
                    setSoftAppID={setSoftAppID}
                  />
                  <OperationSystemSelect
                    osID={osID}
                    setOsID={setOsID}
                  />
                  <VersionProgramInput
                    versionProgram={versionProgram}
                    setVersionProgram={setVersionProgram}
                  />

                  <div className="col-12">
                    <label>Название ветки</label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-git"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите название ветки"
                        name="branch"
                        onChange={(e) => {
                          setBranch(e.target.value);
                        }}
                        value={branch}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <label>sha256</label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-hash"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Введите хэш-сумму"
                        name="sha256"
                        onChange={(e) => {
                          setSha256Hash(e.target.value);
                        }}
                        value={sha256Hash}
                      />
                    </div>
                  </div>

                  <IsFullReleaseCheckbox
                    isFullRelease={isFullRelease}
                    setIsFullRelease={setIsFullRelease}
                  />

                  <DescriptionInput
                    description={description}
                    setDescription={setDescription}
                  />
                  <ChangeLogInput
                    changeLog={changeLog}
                    setChangeLog={setChangeLog}
                  />

                  <ReleaseDateInput
                    releaseDate={releaseDate}
                    setReleaseDate={setReleaseDate}
                  />

                  {osID === 3 && (
                    <IsUploadErrorCheckbox
                      isUploadError={isUploadError}
                      setIsUploadError={setIsUploadError}
                    />
                  )}

                  {isUploadError && (
                    <div className="col-12">
                      <label>ID загруженного файла</label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-mailbox"></i>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Введите версию ПО"
                          name="name"
                          onChange={e => setNumUploadID((Number(e.target.value)))}
                          value={numUploadID}
                        />
                      </div>
                    </div>
                  )}

                  {!isUploadError && (
                    <UploadProgramInput
                      isFileUploaded={isFileUploaded}
                      setIsFileUploaded={setIsFileUploaded}
                      setUploadedFileID={setUploadedFileID}
                      isFileResetForm={isFileResetForm}
                      setIsFileResetForm={setIsFileResetForm}
                    />
                  )}

                  <div className="col-12">
                    <div className="float-end mt-4">
                      <OverlayTrigger overlay={isCheckedSubmitButton ? (
                        <Tooltip id="tooltip-disabled">
                          {(isCheckedSubmitButton && !isFormComplete()) ? `Не заполнены следующие поля: ${getMissingFields()}` : ""}
                        </Tooltip>
                      ) : <></>}>
                        <div className="d-inline-block">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-primary px-4 float-end"
                            disabled={isCheckedSubmitButton}
                            style={{ marginLeft: "auto" }}
                          >
                            Создать
                          </button>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  )
    ;
};

export default SoftwareUploadPage;
