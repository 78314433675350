import { ChangeEvent, FC, useCallback, useEffect, useId, useState } from "react";

import useDebounce from "@hooks/useDebounce";
import useToast from "@hooks/useToast";
import { ILicense } from "@interfaces/ILicenses";
import { licenseService } from "@services/licenseService";

interface DescriptionRowProps {
  item: ILicense;
}

interface IDescriptionInput {
  text: string;
  id: number;
}

const DescriptionRow: FC<DescriptionRowProps> = ({ item }) => {
  const id = useId();
  const { addToast } = useToast();

  const [description, setDescription] = useState<IDescriptionInput>({
    text: "",
    id: 0
  });
  const debouncedSetDescription = useDebounce(description, 500);
  const [flag, setFlag] = useState<boolean>(false);

  const handleTextAreaUpdate = (e: ChangeEvent<HTMLTextAreaElement>, itemID: number) => {
    if (description.text !== "")
      setFlag(true);

    setDescription(prevState => ({
      ...prevState,
      "text": e.target.value,
      "id": itemID
    }));
  };

  const updateLicenseDescription = useCallback(async () => {
    try {
      const response = await licenseService.updateLicenseDescription(
        description.id,
        description.text
      );
      addToast(response.data.message, "success");
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) {
        addToast(e.response.data.message, "warning");
      } else if (e.response.status >= 500) {
        addToast(e.response.data.message, "error");
      }
    }
  }, [addToast, description.id, description.text]);

  useEffect(() => {
    if (debouncedSetDescription) {
      if (description.text !== "" || (description.text === "" && flag)) {
        updateLicenseDescription();
        setFlag(false);
      }

    }
  }, [debouncedSetDescription]);

  return (
    <td className={"align-middle text-center"}>
      <div className="form-floating">
        <textarea
          id={`lic-description-${id}`}
          className="form-control"
          placeholder="Leave a comment here"
          onChange={(e) => handleTextAreaUpdate(e, item.ID)}
          defaultValue={`${item.description ? item.description : ""}`}
        />
        <label htmlFor={`lic-description-${id}`}>Описание</label>
      </div>
    </td>
  );
};

export default DescriptionRow;